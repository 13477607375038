import styled from "@emotion/styled";
import {
  Breakpoints,
  Colors,
  Transitions,
} from "../../../assets/variables/variables";
import { css } from "@emotion/react";

export const SliderContainer = styled.div`
  position: relative;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;

  img {
    width: 100%;
  }
`;

export const SliderMain = styled.div`
  height: 600px;
  width: 100%;
  cursor: zoom-in;
  width: calc(100% - 100px);

  img {
    width: 100%;
    height: 100%;
  }

  .slick-list {
    overflow: hidden;
  }

  .slick-track {
    display: flex;
  }

  .slick-slider,
  .slick-list,
  .slick-track {
    user-select: none;
    position: relative;
    padding: 0;
    width: 100%;
    height: 100%;
  }

  .slick-slide {
    height: 100%;

    > div {
      height: 100%;

      > div {
        height: 100%;
      }
    }
  }

  @media only screen and (max-width: ${Breakpoints.$large}) {
    height: 500px;
  }

  @media only screen and (max-width: ${Breakpoints.$medium}) {
    height: 450px;
  }

  @media only screen and (max-width: ${Breakpoints.$small}) {
    height: 350px;
  }
`;

export const ArrowSlider = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: -50px;
  width: 50px;
  height: 85px;
  z-index: 1;
  cursor: pointer;
  user-select: none;
  transform: translateY(-50%);
  transition: 0.3s ${Transitions.$linearSmooth};
  border: 2px solid ${Colors.$primaryColor};
  border-right: none;

  &:hover {
    border-radius: 6px;
  }

  &:last-of-type {
    border-left: none;
    border-right: 2px solid ${Colors.$primaryColor};
    left: initial;
    right: -50px;
  }

  svg {
    width: 25px;
    height: 25px;
  }

  ${(props) =>
    props.type === "main"
      ? css`
          background-color: transparent;
        `
      : css`
          width: 40px;
          height: 40px;
          background-color: rgba(0, 0, 0, 0.7);
          left: calc(15% - 65px);

          &:last-of-type {
            right: calc(15% - 65px);
          }

          svg {
            width: 13px;
            height: 13px;

            path {
              fill: #fff;
            }
          }

          &:hover {
            background-color: #000;
          }

          @media only screen and (max-width: ${Breakpoints.$large}) {
            left: calc(10% - 65px);

            &:last-of-type {
              right: calc(10% - 65px);
            }
          }

          @media only screen and (max-width: ${Breakpoints.$medium}) {
            display: none;
          }
        `}
`;

export const SliderModal = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgb(0 0 0 / 80%);

  img {
    margin: 30px;
    width: calc(100% - 60px);
    height: calc(100% - 60px);
    object-fit: contain;
    user-select: none;
  }

  .close-btn {
    position: absolute;
    top: 15px;
    right: 15px;

    &:before,
    &:after {
      background-color: #fff;
    }
  }
`;

export const ImageCount = styled.div`
  display: flex;
  position: absolute;
  left: 10px;
  top: 10px;

  span {
    display: inline-block;
    padding: 0 5px;
  }

  p,
  span {
    font-size: 18px;
    color: #fff;

    @media only screen and (max-width: ${Breakpoints.$medium}) {
      font-size: 16px;
    }
  }
`;
