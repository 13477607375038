import { cubicBezier } from "framer-motion/dom";

export const squareAnimation = {
  initial: {
    opacity: 0,
    x: "-40px",
  },
  initialAnimate: {
    opacity: 1,
    x: "0",
  },
  anime: {
    bottom: ["-37px", "-37px", "0px", "0px", "-37px"],
    left: ["-37px", "0px", "0px", "-37px", "-37px"],
  },
  transition: {
    delay: 3.5,
    duration: 6,
    ease: cubicBezier(0.25, 1, 0.5, 1),
    times: [0, 0.2, 0.3, 0.1, 1, 1],
    repeat: Infinity,
    repeatDelay: 0.8,
    type: "keyframes",
  },
  parentTransition: {
    delay: 1.5,
    type: "spring",
  },
};

export const imageAnimation = {
  initial: {
    clipPath: "polygon(0 0, 100% 0, 100% 0, 0 0)",
  },
  animate: {
    clipPath: "polygon(0 100%, 100% 100%, 100% 0, 0 0)",
  },
  transition: {
    duration: 2.2,
    delay: 0.6,
    ease: cubicBezier(0.17, 0.92, 0.52, 0.98),
  },
};

export const squareAnimationReverse = {
  anime: {
    bottom: ["-30px", "-30px", "0px", "0px", "-30px"],
    right: ["-37px", "0px", "0px", "-37px", "-37px"],
  },
  transition: {
    duration: 6,
    ease: cubicBezier(0.25, 1, 0.5, 1),
    times: [0, 0.2, 0.3, 0.1, 1, 1],
    repeat: Infinity,
    repeatDelay: 1.5,
    type: "keyframes",
  },
};

export const titleAnimation = {
  initial: {
    y: "100%",
  },
  animate: {
    y: "0",
  },
  viewport: {
    once: true,
    margin: "0px  0px 100px 0px",
  },
  transition: {
    duration: 0.9,
    ease: "backInOut",
  },
};

export const textAnimation = {
  initial: {
    y: "100%",
    scaleX: 0.9,
  },
  animate: {
    y: "0",
    scaleX: 1,
  },
  viewport: {
    once: true,
    margin: "0px  0px 100px 0px",
  },
  transition: {
    duration: 0.9,
    ease: "backInOut",
    delay: 0.5,
  },
};

export const buttonAnimation = {
  initial: {
    x: "100%",
    opacity: 0,
  },
  animate: {
    x: "0",
    opacity: 1,
  },
  viewport: {
    once: true,
    amount: 0.00001,
  },
  transition: {
    duration: 1.4,
    ease: cubicBezier(0.25, 1, 0.5, 1),
    delay: 1.3,
  },
};
