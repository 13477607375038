import { useEffect, useState } from "react";
import Product from "../product";
import { Row } from "../../layouts";
import Button from "../button";
import { SubTitle, Title } from "../../layouts/layouts";

const OurProducts = (data) => {
  const [hotOfferData, setHotOffer] = useState();

  useEffect(() => {
    if (data) {
      setHotOffer(data.fields);
    }
  }, []);

  if (!hotOfferData) return;

  return (
    <div className="py-70">
      <SubTitle className="mb-30 text-center" font="Regular">
        DISA NGA PRODUKTET TONA
      </SubTitle>
      <Row>
        {hotOfferData.content.map((product, index) => {
          return (
            <Product
              key={index}
              data={product.fields}
              buyButtonActive={true}
              size={"col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3"}
            />
          );
        })}
      </Row>
      <div className="w-full mb-20 text-center">
        <Button text="Gjitha Produktet" to={`/`} />
      </div>
    </div>
  );
};

export default OurProducts;
