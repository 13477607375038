import React, { useState, useEffect } from "react";
import ProductList from "../components/products";
import { Container } from "../layouts";
import { getQueryData } from "../config/data";
import { SearchTitle } from "../components/navigation/layouts";
import { useSearchParams } from "react-router-dom";

const Search = () => {
  const [products, setProducts] = useState([]);

  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get("q") || "";

  useEffect(() => {
    getQueryData({
      type: "products",
      searchTerm: searchQuery,
    })
      .then((data) => {
        const dataResult = data.result.productCollection.items;
        const result = dataResult.length > 0 ? dataResult : null;
        setProducts(result);
      })
      .catch((error) => {
        console.error("Error: 'e903'", error);
      });
  }, [searchQuery]);

  return (
    <Container>
      <SearchTitle>
        <h3>Rezultatet e kerkuara per: </h3>
        <p>{searchQuery}</p>
      </SearchTitle>
      {products ? (
        <ProductList data={products} activeFilters={false} />
      ) : (
        <SearchTitle>
          <h3>Nuk ka produkte me kete kerkim</h3>
        </SearchTitle>
      )}
    </Container>
  );
};

export default Search;
