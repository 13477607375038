import Image from "../image";
import Button from "../button";
import { useRef } from "react";
import { Block, Flex } from "../../layouts";
import { CategoryTitleEffect } from "./layouts";
import { Paragraph, Title, SubTitle } from "../../layouts/layouts";
import { buttonAnimation, textAnimation, titleAnimation } from "./animevars";
import {
  motion,
  useSpring,
  useScroll,
  useInView,
  useTransform,
} from "framer-motion";

const CategoryBlock = ({ data, index }) => {
  const titeRef = useRef(null);
  const paragraphRef = useRef(null);
  const isTitleInView = useInView(titeRef, { once: true });
  const isParagraphInView = useInView(titeRef, { once: true });

  const {
    slug,
    reverse = false,
    description = "",
    name = "Category Title",
    categoryImageVertical = "",
  } = data.fields;

  const blockRef = useRef();

  const { scrollYProgress } = useScroll({
    target: blockRef,
    offset: ["0 1", "0.9 1"],
    smooth: 10000,
  });

  const smoothProgres = useSpring(scrollYProgress, {
    mass: 0.1,
    stiffness: 150,
    damping: 10,
    restDelta: 0.001,
  });

  const tagTransfrom = useTransform(
    scrollYProgress,
    [0.6, 1],
    ["150px", "0px"]
  );

  const opacityTransform = useTransform(smoothProgres, [0.4, 1], [0, 1]);
  const imageMovementTransform = useTransform(
    smoothProgres,
    [0.4, 1],
    reverse ? ["150px", "0px"] : ["-150px", "0px"]
  );

  return (
    <Flex
      key={index}
      id={name}
      className={`align-center pb-70 ${
        reverse && "flex-reverse"
      } flex-lg-column`}
    >
      <Block className="w-60">
        <motion.div
          ref={blockRef}
          style={{ x: imageMovementTransform, opacity: opacityTransform }}
        >
          <Image
            source={categoryImageVertical.fields.file.url}
            classList={`w-full object-cover pr-120 ${reverse && "pr-0 pl-120"}`}
            style={{ aspectRatio: "8.5/9" }}
            alt={categoryImageVertical.fields.file.fileName}
          />
        </motion.div>
        <CategoryTitleEffect
          className={`${reverse && "right-intial left-10"}`}
          style={{ y: tagTransfrom, opacity: opacityTransform }}
        >
          <SubTitle>{name}</SubTitle>
        </CategoryTitleEffect>
      </Block>
      <Block className={`w-40 text-center pl-20 ${reverse && "pl-0 pr-20"}`}>
        <motion.div className="overflow-hidden mb-30" ref={titeRef}>
          <Title
            font="Bold"
            letterSpace="4"
            variants={titleAnimation}
            initial="initial"
            animate={isTitleInView ? "animate" : "initial"}
            viewport={titleAnimation.viewport}
            transition={titleAnimation.transition}
          >
            Trendy {name}
          </Title>
        </motion.div>
        <motion.div className="overflow-hidden mb-30" ref={paragraphRef}>
          <Paragraph
            className="pb-30"
            variants={textAnimation}
            initial="initial"
            animate={isParagraphInView ? "animate" : "initial"}
            viewport={textAnimation.viewport}
            transition={textAnimation.transition}
          >
            {description}
          </Paragraph>
        </motion.div>
        <div className="d-inline-block overflow-hidden w-fit pr-1">
          <Button
            text="See all products"
            to={`/category/${slug}`}
            anime={buttonAnimation}
          />
        </div>
      </Block>
    </Flex>
  );
};

export default CategoryBlock;
