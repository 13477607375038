import { cubicBezier } from "framer-motion";

export const titleAnimation = {
  initial: {
    y: "150%",
    scaleX: 0.8,
  },
  animate: {
    y: "0",
    scaleX: 1,
  },
  viewport: {
    once: false,
    margin: "0px  0px 200px 0px",
  },
  transition: {
    duration: 0.9,
    ease: "backInOut",
    delay: 0.3,
  },
};

export const textAnimation = {
  initial: {
    y: "150%",
    scaleX: 0.8,
  },
  animate: {
    y: "0",
    scaleX: 1,
  },
  viewport: {
    once: false,
    margin: "0px  0px 200px 0px",
  },
  transition: {
    duration: 1.2,
    ease: "backInOut",
    delay: 0.5,
  },
};

export const buttonAnimation = {
  initial: {
    x: "100%",
    opacity: 0,
  },
  animate: {
    x: "0",
    opacity: 1,
  },
  viewport: {
    once: false,
    amount: 0.00001,
  },
  transition: {
    duration: 0.8,
    ease: cubicBezier(0.25, 1, 0.5, 1),
    delay: 0.7,
  },
};
