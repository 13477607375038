import React, { useEffect, useRef, useState } from "react";
import { Container, Flex } from "../../layouts";
import { BigTitle, Paragraph, SubTitle } from "../../layouts/layouts";
import { Service, ServiceIcon, ServiceTag } from "./layouts";
import serviceBackground from "../../assets/images/ourservice-background.jfif";
import Image from "../image";
import { motion, useSpring, useScroll, useTransform } from "framer-motion";
import { serviceAnimation } from "./animevars";

const Ourservices = (data) => {
  const [servicesData, setServicesData] = useState();
  const imageRef = useRef();

  useEffect(() => {
    if (data) {
      setServicesData(data.fields);
    }
  }, []);

  const { scrollYProgress } = useScroll({
    target: imageRef,
    offset: ["0 1", "0.9 1"],
    smooth: 10000,
  });

  const smoothProgres = useSpring(scrollYProgress, {
    mass: 0.1,
    stiffness: 150,
    damping: 10,
    restDelta: 0.001,
  });

  const opacityTransform = useTransform(smoothProgres, [0.4, 1], [0, 1]);

  if (!servicesData) return;

  const {
    title = "",
    subtitle = "",
    services = null,
    backgroundImage = null,
  } = servicesData;

  return (
    <Flex className="p-relative py-70">
      <motion.div
        ref={imageRef}
        className="p-absolute-fixed"
        style={{ opacity: opacityTransform }}
      >
        <Image
          classList="p-absolute-fixed"
          source={backgroundImage.fields.file.url}
          alt={"service-background-image"}
        />
      </motion.div>
      <Container className="index-positive-1">
        <Flex className="text-center flex-column">
          <ServiceTag className="mb-10 color-white">{subtitle}</ServiceTag>
          <BigTitle className="mb-40 color-white" font="Bold">
            {title}
          </BigTitle>
        </Flex>
        <Flex className="flex-wrap justify-between">
          {services &&
            services.map((service, index) => {
              return (
                <Service
                  key={index}
                  custom={index}
                  initial="initial"
                  className="mb-20"
                  variants={serviceAnimation}
                  whileInView={"animate"}
                  viewport={serviceAnimation.viewport}
                  transition={{
                    type: "spring",
                    duration: 0.8,
                    ease: [0.25, 1, 0.5, 1],
                    delay: index * 0.2,
                  }}
                >
                  <div className="p-relative flex-25 py-20 px-10">
                    <ServiceIcon>
                      <svg
                        width="16"
                        height="16"
                        fill="#fff"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4.807 5.001C4.021 6.298 3.203 7.6 2.5 8.917v.971h2.905V11h1.112V9.888h.733V8.93h-.733V5.001h-1.71zm-1.23 3.93v-.032a46.781 46.781 0 0 1 1.766-3.001h.062V8.93H3.577zm9.831-3.93h-1.306L9.835 7.687h-.057V5H8.59v6h1.187V9.075l.615-.699L12.072 11H13.5l-2.232-3.415 2.14-2.584z" />
                        <path d="M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z" />
                      </svg>
                      <span />
                    </ServiceIcon>
                  </div>
                  <Flex className="flex-column flex-75 pl-10">
                    <SubTitle className="pb-10 color-white" font="Light">
                      {service.fields.title}
                    </SubTitle>
                    <Paragraph font="Light">
                      {service.fields.description}
                    </Paragraph>
                  </Flex>
                </Service>
              );
            })}
        </Flex>
      </Container>
    </Flex>
  );
};

export default Ourservices;
