import { useParams } from "react-router";
import ProjectHero from "../components/project-hero";
import { useEffect, useState } from "react";
import { getQueryData } from "../config/data";
import ProjectContent from "../components/project-content";

const SingleProject = () => {
  const [projectData, setProjectData] = useState(null);
  const params = useParams();

  useEffect(() => {
    getQueryData({
      type: "projects",
      slug: params.slug,
      imagesLimit: 20,
      itemsLimit: 1,
    })
      .then((data) => {
        setProjectData(data.result.projectCollection.items[0]);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [params]);

  if (!projectData) return;

  return (
    <>
      <ProjectHero data={projectData} />
      <ProjectContent data={projectData} />
    </>
  );
};

export default SingleProject;
