import styled from "@emotion/styled";
import { Colors } from "../../assets/variables/variables.js";

export const SubscribeForm = styled.div`
  border-radius: 100px;
  border: 1px solid ${Colors.$primaryDarkColor};
  background-color: #fff;
`;

export const SubscribeInput = styled.input`
  padding: 10px 50px 10px 25px;
`;

export const SubscribeButton = styled.button`
  padding: 10px 25px;
  background-color: ${Colors.$primaryDarkColor};

  &:hover {
    cursor: pointer;
  }
`;
