import { useEffect, useState } from "react";
import Slider from "react-slick";
import Image from "../../image";
import Icon from "../../../assets/icons/icon";
import { ArrowSlider, SliderContainer, SliderMain } from "./layouts";
// import { useExtrasData } from "../../../Contexts/ExtrasContext";

export function MainNextArrow(props) {
  const { onClick, type } = props;
  return (
    <ArrowSlider className="arrow-slider" type={type} onClick={onClick}>
      <Icon icon="sliderArrowRight" />
    </ArrowSlider>
  );
}

export function MainPrevArrow(props) {
  const { onClick, type } = props;

  return (
    <ArrowSlider className="arrow-slider" type={type} onClick={onClick}>
      <Icon icon="sliderArrowLeft" />
    </ArrowSlider>
  );
}

const settings = {
  swipe: false,
  infinite: true,
  lazyLoad: true,
  autoplay: true,
  slidesToShow: 1,
  centerMode: true,
  slidesToScroll: 1,
  focusOnSelect: true,
  autoplaySpeed: 2000,
  centerPadding: "0px",
  cssEase: "cubic-bezier(0.25, 1, 0.5, 1.1)",
  nextArrow: <MainNextArrow type={"main"} />,
  prevArrow: <MainPrevArrow type={"main"} />,
};

const GalerySlider = ({ data }) => {
  const [sliderData, setSliderData] = useState(null);

  useEffect(() => {
    if (data) {
      setSliderData(data.items);
    }
  }, [data]);

  return (
    <SliderContainer>
      <SliderMain>
        <Slider {...settings}>
          {sliderData &&
            sliderData.map((item, index) => (
              <div key={index}>
                <Image source={item.url} />
              </div>
            ))}
        </Slider>
      </SliderMain>
    </SliderContainer>
  );
};

export default GalerySlider;
