import styled from "@emotion/styled";
import { motion } from "framer-motion";
import {
  Colors,
  Fonts,
  Transitions,
} from "../../assets/variables/variables.js";

export const BtnContainer = styled(motion.div)`
  display: inline-block;
  width: fit-content;
  min-width: 120px;
`;

export const Btn = styled.button`
  font-size: 12px;
  letter-spacing: 1.2px;
  padding: 12px 24px;
  border: 1px solid rgb(120, 120, 120);
  background: transparent;
  transition: 0.3s ${Transitions.smooth};
  position: relative;
  overflow: hidden;
  z-index: 1;
  font-family: ${Fonts.$light};

  &:hover {
    color: #fff;

    &:after {
      opacity: 1;
      width: 85%;
    }
  }

  &:after {
    content: "";
    opacity: 0;
    width: 39%;
    height: 400px;
    top: -152px;
    left: 10px;
    background-color: ${Colors.$primaryColor};
    position: absolute;
    z-index: -1;
    transform: rotate(-37deg);
    transition: 0.3s ${Transitions.$superSmooth};
  }
`;

export const SecondaryBtn = styled.button`
  font-size: 12px;
  letter-spacing: 1px;
  padding: 12px 24px;
  color: #fff;
  border: 1px solid ${Colors.$primaryColor};
  background-color: ${Colors.$primaryColor};
  transition: 0.3s ${Transitions.smooth};
  z-index: 1;

  // &:before {
  //   content: "";
  //   opacity: 0;
  //   width: 39%;
  //   height: 400px;
  //   top: -152px;
  //   left: 10px;
  //   background-color: ${Colors.$primaryColor};
  //   position: absolute;
  //   z-index: -1;
  //   transform: rotate(-37deg);
  //   transition: 0.3s ${Transitions.$superSmooth};
  // }

  &:hover {
    color: ${Colors.$primaryColor};
    // background-color: #fff;

    // &:before {
    //   opacity: 1;
    //   width: 85%;
    // }
  }
`;
