import styled from "@emotion/styled";

export const Flex = styled.div`
  display: flex;
`;

export const Block = styled.div`
  display: inline-block;
  position: relative;
`;

export const Container = styled.div`
  max-width: 1200px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`;

export const DivHalf = styled.div`
  position: relative;
  display: inline-block;
  width: 50%;
`;

export const Row = styled.div`
  display: flex;
  display: -ms-flexbox;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`;

export const CloseBtn = styled.div`
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: relative;
  
  &:before,
  &:after {
    position: absolute;
    content: "";
    width: 35px;
    height: 2px;
    top: 13px;
    left: -3px;
    background-color: #000;
  }

  &:before {
    transform: rotate(-45deg);
  }

  &:after {
    transform: rotate(45deg);
  }
`;
