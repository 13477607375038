import {
  Title,
  Content,
  Subtitle,
  ProjectInfo,
  SeeMoreLink,
  ProjectContainer,
  ImageContainer,
} from "./layouts";
import RenderText from "../../render-text";
import Image from "../../image";

const Project = ({ data }) => {
  const {
    slug,
    reverse,
    title = "",
    subtitle = "",
    description = "",
    imagesCollection = null,
  } = data;

  return (
    <ProjectContainer reverse={reverse} to={`/projects/${slug}`}>
      <Content reverse={reverse}>
        {RenderText(description)}
        <SeeMoreLink decoration={true}>Shiko me shume...</SeeMoreLink>
      </Content>
      <ProjectInfo reverse={reverse}>
        <div>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </div>
        <SeeMoreLink>Shiko me shume...</SeeMoreLink>
      </ProjectInfo>
      <ImageContainer reverse={reverse} className="image-animation">
        <Image source={imagesCollection.items[0].url} />
      </ImageContainer>
    </ProjectContainer>
  );
};

export default Project;
