import React from "react";
import Image from "../image";
import subscribeImg from "../../assets/images/subscribeBackground.jpg";
import { SubscribeForm, SubscribeInput, SubscribeButton } from "./layouts";

const Subscribe = () => {
  return (
    <div className="block d-flex justify-center py-70 p-relative">
      <Image
        source={subscribeImg}
        classList={"p-absolute-fixed object-cover object-center opacity-5"}
        alt={"subscriber"}
      />
      <div className="p-relative w-30 text-center">
        <h3 className="pb-20"> Get 30% Off</h3>
        <p className="pb-50 w-60 d-inline-block">
          Get 30% off your first purchase by joining our mailing list, and
          reciving some of our best offers
        </p>
        <SubscribeForm className="d-flex w-full justify-between">
          <SubscribeInput
            type="email"
            placeholder="Your Email"
            className="
              background-transparent 
              w-full 
              border-none 
              outline-none 
              radius-50-px"
          />
          <SubscribeButton className="border-none radius-50-px color-white">
            Subscribe
          </SubscribeButton>
        </SubscribeForm>
      </div>
    </div>
  );
};

export default Subscribe;
