import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Flex } from "../../layouts";
import { Title } from "../../layouts/layouts";
import { Summersalebtn, Hotsalebackground } from "./layouts";
import Image from "../image";
import Card from "./card";
import { btnAnime, titleAnime } from "./animevars";
import { useScroll, useSpring, useTransform } from "framer-motion";

const Hotoffers = (data) => {
  const [hotOfferData, setHotOffer] = useState();

  useEffect(() => {
    if (data) {
      setHotOffer(data.fields);
    }
  }, []);

  const ref = useRef();
  const { scrollYProgress } = useScroll({
    target: ref,
    smooth: 10000,
    offset: ["0 1", "1 1"],
  });
  const smoothProgres = useSpring(scrollYProgress, {
    mass: 1.5,
    damping: 20,
    stiffness: 150,
    restDelta: 0.001,
  });

  const opacityTransform = useTransform(smoothProgres, [0.4, 1], [0, 1]);

  if (!hotOfferData) return;
  const {
    buttonLink = "",
    summerPosts = "",
    summerTitle = "",
    saleBackgroundImage = null,
  } = hotOfferData;

  return (
    <Flex className="mb-100 p-relative">
      <Hotsalebackground ref={ref} style={{ opacity: opacityTransform }}>
        <Image
          alt={summerTitle}
          classList="p-absolute-fixed"
          source={saleBackgroundImage.fields.file.url}
        />
      </Hotsalebackground>
      <Container className="d-flex">
        <Flex className="w-70">
          {summerPosts &&
            summerPosts.map((post, index) => {
              return (
                <Card
                  data={post}
                  key={index}
                  reverse={index === 0 ? false : true}
                />
              );
            })}
        </Flex>
        <Flex className="w-30 flex-column align-center justify-center">
          <Title
            className="pb-20"
            variants={titleAnime}
            initial="initial"
            whileInView="animate"
            viewport={titleAnime.viewport}
            transition={titleAnime.transition}
          >
            {summerTitle}
          </Title>
          <Link to={buttonLink} className="text-decoration-none">
            <Summersalebtn
              variants={btnAnime}
              initial="initial"
              whileInView="animate"
              viewport={btnAnime.viewport}
              transition={btnAnime.transition}
            >
              Blej Tani
            </Summersalebtn>
          </Link>
        </Flex>
      </Container>
    </Flex>
  );
};

export default Hotoffers;
