import styled from "@emotion/styled";
import { Colors, Transitions } from "../../assets/variables/variables";
import { Link } from "react-router-dom";

export const ProductContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 30px;
`;

export const ProductImgContainer = styled.div`
  padding: 15px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => (props.activeFilter ? "255px" : "310px")};
  width: 100%;
  margin-bottom: 10px;
  border-radius: 6px;
  // background-color: #f3f3f3;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background: #fff;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const PriceInputField = styled.input`
  border: none;
  outlin: none;
`;

export const WishlistContainer = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`;

export const WishBtn = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s ${Transitions.$superSmooth};

  svg {
    width: 20px;
    height: 20px;
  }

  &:hover {
    transform: scale(1.2);
  }
`;

export const Hotsaletag = styled.div`
  top: -20px;
  left: -15px;
  color: #fff;
  padding: 15px;
  height: 20px;
  display: flex;
  min-width: 85px;
  font-weight: bold;
  position: absolute;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;

  border-radius: 6px 6px 6px 0px;
  background-color: ${Colors.$primaryColor};

  &:before {
    content: "";
    position: absolute;
    bottom: -26px;
    left: 0;
    border-top: 14px solid ${Colors.$primaryDarkColor};
    border-left: 15px solid ${Colors.$primaryDarkColor};
    border-bottom: 13px solid transparent;
    border-left: 14px solid transparent;
  }

  p {
    font-size: 18px;
    font-weight: 500;
  }
`;
