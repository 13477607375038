import { useState, useEffect } from "react";
import Icon from "../../assets/icons/icon";
import { ProductPrice, ProductTitle } from "../../layouts/layouts";
import { HotsaleBtn } from "../hot-offers/layouts";
import {
  WishBtn,
  ProductContainer,
  WishlistContainer,
  ProductImgContainer,
  Hotsaletag,
} from "./layouts";

const Product = ({ size, data, buyButtonActive = false }) => {
  const [productOnList, setProductOnList] = useState(false);
  const [localWishlisht] = useState(
    JSON.parse(localStorage.getItem("wishlist"))
  );

  const {
    sys,
    slug,
    price = "",
    salePrice = "",
    title = "",
    images = null,
    imagesCollection = null,
  } = data;

  const discountPercentage = salePrice
    ? ((salePrice - price) / salePrice) * 100
    : 0;

  function toggleWishlist(e) {
    e.preventDefault();

    const existedWishlist = localStorage.getItem("wishlist")
      ? JSON.parse(localStorage.getItem("wishlist"))
      : [];
    const isProductInWishlist = existedWishlist.includes(sys.id);

    let updatedWishlist = [];

    if (isProductInWishlist) {
      setProductOnList(false);
      updatedWishlist = existedWishlist.filter((id) => id !== sys.id);
    } else {
      setProductOnList(true);
      updatedWishlist = [...existedWishlist, sys.id];
    }
    localStorage.setItem("wishlist", JSON.stringify(updatedWishlist));
  }

  function checkIfProductOnWishlist() {
    if (localWishlisht.includes(sys.id)) {
      setProductOnList(true);
    }
  }

  useEffect(() => {
    if (sys && localWishlisht) {
      checkIfProductOnWishlist();
    }
  }, []);

  return (
    <ProductContainer className={size} to={`/products/${slug}`}>
      <ProductImgContainer>
        {!buyButtonActive && salePrice && (
          <Hotsaletag>
            <p>-{Math.round(discountPercentage)}%</p>
          </Hotsaletag>
        )}
        {imagesCollection && (
          <img src={imagesCollection.items[0].url} alt="product" />
        )}
        {images && <img src={images[0].fields.file.url} alt="product" />}
        <WishlistContainer>
          <WishBtn onClick={toggleWishlist}>
            <Icon icon={`${productOnList ? "heartRemove" : "heartAdd"}`} />
          </WishBtn>
        </WishlistContainer>
      </ProductImgContainer>
      <ProductTitle className="pb-5">{title}</ProductTitle>
      <ProductPrice>{price}€</ProductPrice>
      {buyButtonActive && (
        <HotsaleBtn className="mb-30 mt-5" type={"secondary"}>
          <p>See product</p>
        </HotsaleBtn>
      )}
    </ProductContainer>
  );
};

export default Product;
