import { useState, useEffect } from "react";
import CategoryBlock from "./categoryBlock";

const Categorysection = (data) => {
  const [categoryBlock, setCategoryBlock] = useState();

  useEffect(() => {
    if (data) {
      setCategoryBlock(data.fields);
    }
  }, []);

  if (!categoryBlock) return;
  const { content = null } = categoryBlock;

  return (
    <div className="mt-70 mb-70">
      {content &&
        content.map((category, index) => {
          return <CategoryBlock data={category} index={index} />;
        })}
    </div>
  );
};

export default Categorysection;
