import React from "react";
import Image from "../image";
import ModalImg from "../../assets/images/workcategoryimage1.jpg";
import { CloseBtn, DivHalf } from "../../layouts";
import { SubTitle } from "../../layouts/layouts";
import {
  CloseBtnContainer,
  Modal,
  ModalConatiner,
  ModalContent,
  SubscribeInput,
} from "./layouts";
import Button from "../button";

const SubscribeModal = ({ removeModal }) => {
  return (
    <ModalConatiner className="show">
      <Modal>
        <CloseBtnContainer onClick={removeModal}>
          <CloseBtn />
        </CloseBtnContainer>
        <DivHalf className="h-full">
          <Image
            source={ModalImg}
            classList="w-full h-full"
            alt={"image-subscribe"}
          />
        </DivHalf>
        <ModalContent>
          <SubTitle>Merr 10% zbritje nga porosija juaj e pare!</SubTitle>
          <div className="d-flex flex-column">
            <SubscribeInput
              type="email"
              placeholder="Your Email"
              className="
              mb-20
              w-full 
              border-none 
              outline-none
              radius-50-px
              background-transparent"
            />
            <Button text="subscribe" secondary parentSyle="max-w-100 w-full" />
          </div>
        </ModalContent>
      </Modal>
    </ModalConatiner>
  );
};

export default SubscribeModal;
