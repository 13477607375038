import React from "react";
import Logo from "../logo";
import { Link } from "react-router-dom";
import { Container, Flex } from "../../layouts";
import { FooterContainer } from "../../layouts/layouts";
import { EmailIcon, FacebookIcon, FooterLink, InstagramIcon } from "./layouts";

const Footer = () => {
  return (
    <FooterContainer className="flex w-full color-white">
      <Container>
        <div className="d-flex py-50">
          <div className="flex-40 pr-15 d-flex flex-column">
            <Logo className="mb-20" />
            <Link to="#" className="mb-5 text-decoration-none color-white">
              T3118 Doctors Drive,Los Angeles, California 90017
            </Link>
            <Link to="#" className="mb-5 text-decoration-none color-white">
              Costumer Support: 01928402101
            </Link>
            <Link to="#" className="mb-20 text-decoration-none color-white">
              Email: Support@maynooth.com
            </Link>
            <Flex>
              <a
                href="https://www.facebook.com/as.welder/"
                target="_blank"
                rel="noreferrer"
                className="mr-10"
              >
                <FacebookIcon />
              </a>
              <a
                href="https://www.instagram.com/as.welder/"
                target="_blank"
                rel="noreferrer"
                className="mr-10"
              >
                <InstagramIcon />
              </a>
              <a href="mailto:as.welders@gmail.com" className="mr-10">
                <EmailIcon />
              </a>
            </Flex>
          </div>
          <div className="flex-20 pl-15 pr-15 d-flex flex-column">
            <h5 className="mb-20">Information</h5>
            <FooterLink>
              <Link to="#" className="mb-10 text-decoration-none color-white">
                About Us
              </Link>
            </FooterLink>
            <FooterLink>
              <Link to="#" className="mb-10 text-decoration-none color-white">
                Terms & Condition
              </Link>
            </FooterLink>
            <FooterLink>
              <Link to="#" className="mb-10 text-decoration-none color-white">
                Security & Privacy
              </Link>
            </FooterLink>
            <FooterLink>
              <Link to="#" className="mb-10 text-decoration-none color-white">
                Terms & Condition
              </Link>
            </FooterLink>
            <FooterLink>
              <Link to="#" className="mb-10 text-decoration-none color-white">
                Security & Privacy
              </Link>
            </FooterLink>
          </div>
          <div className="flex-20 pl-15 pr-15 d-flex flex-column">
            <h5 className="mb-20">Element</h5>
            <FooterLink>
              <Link to="#" className="mb-10 text-decoration-none color-white">
                column
              </Link>
            </FooterLink>
            <FooterLink>
              <Link to="#" className="mb-10 text-decoration-none color-white">
                Accordion and toggle
              </Link>
            </FooterLink>
            <FooterLink>
              <Link to="#" className="mb-10 text-decoration-none color-white">
                typography
              </Link>
            </FooterLink>
            <FooterLink>
              <Link to="#" className="mb-10 text-decoration-none color-white">
                Pie Chart
              </Link>
            </FooterLink>
            <FooterLink>
              <Link to="#" className="mb-10 text-decoration-none color-white">
                Pricing Table
              </Link>
            </FooterLink>
          </div>
          <div className="flex-20 pl-15 d-flex flex-column">
            <h5 className="mb-20">Help</h5>
            <FooterLink>
              <Link to="#" className="mb-10 text-decoration-none color-white">
                Cancelation & Return
              </Link>
            </FooterLink>
            <FooterLink>
              <Link to="#" className="mb-10 text-decoration-none color-white">
                Pre Order Policy
              </Link>
            </FooterLink>
            <FooterLink>
              <Link to="#" className="mb-10 text-decoration-none color-white">
                How to Order
              </Link>
            </FooterLink>
            <FooterLink>
              <Link to="#" className="mb-10 text-decoration-none color-white">
                Frequent Query
              </Link>
            </FooterLink>
            <FooterLink>
              <Link to="#" className="mb-10 text-decoration-none color-white">
                Contact Us
              </Link>
            </FooterLink>
          </div>
        </div>
        <div className="text-center py-20">
          <Link
            to="#"
            className="d-inline-block text-decoration-none color-white"
          >
            Copyright 2020 maynooth, all rights reserved
          </Link>
        </div>
      </Container>
    </FooterContainer>
  );
};

export default Footer;
