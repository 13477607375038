import React, { useEffect, useRef, useState } from "react";
import Image from "../image";
import { Flex } from "../../layouts";
import Icon from "../../assets/icons/icon";
import { Paragraph } from "../../layouts/layouts";
import DeliveryIcon from "../../assets/images/deliveryIcon.png";
import { SendBuyMessage, euroNumberFormat } from "../../config/functions";
import {
  Slider,
  BuyButton,
  ProductCode,
  ProductTitle,
  BuyParagraph,
  CategoryLink,
  MainBuyButton,
  BuyButtonIcon,
  ImagesGallery,
  MixedCategory,
  ProductLayout,
  OriginalPrice,
  SeperatorLine,
  BreadcrumbsNav,
  ProductDetails,
  PricingSection,
  DiscountedPrice,
  MainImageWrapper,
  BuyButtonParagraph,
  BuyOpptionsContainer,
  ThumbnailImageWrapper,
  ThumbnailGalleryWrapper,
} from "./layouts";
import { useExtrasData } from "../../Contexts/ExtrasContext";

const SingleProductHero = ({ data }) => {
  const [buyActive, setBuyActive] = useState(false);
  const [buyTransition, setBuyTransition] = useState(false);
  const [activeImage, setActiveImage] = useState({ index: 0 });
  const { extrasData } = useExtrasData();

  const {
    code,
    slug,
    price = null,
    salePrice = null,
    title = "Product",
    description = "",
    imagesCollection = null,
    productCategoriesCollection = null,
  } = data;

  const buyDataObj = {
    code,
    slug,
    title,
    price,
    email: extrasData?.buyEmail,
    text: extrasData?.buyMessage,
    subject: extrasData?.buySubject,
    phoneNumber: extrasData?.buyNumber,
  };

  const imagesArray = imagesCollection.items;
  const categories = productCategoriesCollection.items;
  const showArrows = imagesArray.length > 4;
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    setActiveImage({ image: imagesArray[0].url, index: 0 });
  }, [data]);

  function activeBuyButton() {
    setBuyActive(true);
    setTimeout(() => {
      setBuyTransition(true);
    }, 1);
  }

  function changeActivePicture(index) {
    setActiveImage({ image: imagesArray[index].url, index: index });
  }

  function scrollFeaturedImages(direction) {
    if (scrollContainerRef.current) {
      const { current: container } = scrollContainerRef;
      const scrollAmount = container.offsetWidth / 2;

      if (direction === "left" || direction === "right") {
        container.scrollLeft +=
          direction === "left" ? -scrollAmount : scrollAmount;
      }
    }
  }

  return (
    <Flex className="mb-70">
      <ProductLayout>
        <ImagesGallery>
          <MainImageWrapper className="w-60">
            {activeImage && (
              <Image
                source={activeImage.image}
                classList={"w-full h-full object-contain"}
                alt={"single-product"}
              />
            )}
          </MainImageWrapper>
          <Slider>
            {showArrows && (
              <button
                className="slider-arrow left-arrow"
                onClick={() => scrollFeaturedImages("left")}
              >
                <Icon icon="leftArrowIcon" />
              </button>
            )}
            <ThumbnailGalleryWrapper ref={scrollContainerRef}>
              {imagesArray.map((image, index) => {
                return (
                  <ThumbnailImageWrapper
                    key={index}
                    isActive={activeImage.index === index}
                    onClick={() => changeActivePicture(index)}
                  >
                    <Image
                      source={image.url}
                      classList={"w-full h-full"}
                      alt={"single-product"}
                      size="small"
                    />
                  </ThumbnailImageWrapper>
                );
              })}
            </ThumbnailGalleryWrapper>
            {showArrows && (
              <button
                className="slider-arrow right-arrow"
                onClick={() => scrollFeaturedImages("right")}
              >
                <Icon icon="rightArrowIcon" />
              </button>
            )}
          </Slider>
        </ImagesGallery>
        <ProductDetails>
          <Flex className="align-center">
            {categories.length > 0 &&
              categories.map((category, index) => {
                const { name, slug } = category;
                return (
                  <React.Fragment key={index}>
                    <BreadcrumbsNav to={`/category/` + slug}>
                      {name}
                    </BreadcrumbsNav>
                    {categories.length !== index + 1 && (
                      <p className="pr-5">/</p>
                    )}
                  </React.Fragment>
                );
              })}
          </Flex>
          <MixedCategory>
            {categories &&
              categories.length > 0 &&
              categories
                .filter((category) => category.mixedCategory === true)
                .map((category, index) => (
                  <CategoryLink key={index} to={`/category/${category.slug}`}>
                    {category.name}
                  </CategoryLink>
                ))}
          </MixedCategory>
          <ProductTitle className="mb-20">{title}</ProductTitle>
          <PricingSection>
            {salePrice && (
              <OriginalPrice className="mb-20">
                {euroNumberFormat(salePrice)}
              </OriginalPrice>
            )}
            <DiscountedPrice className="mb-20">
              {euroNumberFormat(price)}
            </DiscountedPrice>
          </PricingSection>
          <Paragraph className="mb-30">{description}</Paragraph>
          <Flex className="align-center mb-30">
            <ProductCode className="pr-5">Kodi: </ProductCode>
            <Paragraph>{code}</Paragraph>
          </Flex>
          <SeperatorLine />
          <Flex className="justify-between w-full">
            <div>
              <MainBuyButton to="/" onClick={activeBuyButton}>
                <p>Porosit Tani</p>
                <Image source={DeliveryIcon} alt={"Order now button"} />
              </MainBuyButton>
            </div>
            {buyActive && (
              <BuyOpptionsContainer
                className={
                  buyTransition ? "active align-center" : "align-center"
                }
              >
                <BuyParagraph className="w-md-full pb-md-5">
                  Blej produktin nga platforma që dëshironi:
                </BuyParagraph>
                <Flex className="flex-column">
                  <BuyButton
                    color="#25D366"
                    onClick={() => SendBuyMessage("whatsapp", buyDataObj)}
                  >
                    <BuyButtonIcon>
                      <Icon icon="whatsapp" />
                    </BuyButtonIcon>
                    <BuyButtonParagraph>Whatsapp</BuyButtonParagraph>
                  </BuyButton>
                  <BuyButton
                    color="#d03d2f"
                    onClick={() => SendBuyMessage("email", buyDataObj)}
                  >
                    <BuyButtonIcon>
                      <Icon icon="gmail" />
                    </BuyButtonIcon>
                    <BuyButtonParagraph>Gmail</BuyButtonParagraph>
                  </BuyButton>
                  <BuyButton
                    color="#59267c"
                    onClick={() => SendBuyMessage("viber", buyDataObj)}
                  >
                    <BuyButtonIcon>
                      <Icon icon="viber" />
                    </BuyButtonIcon>
                    <BuyButtonParagraph>Viber</BuyButtonParagraph>
                  </BuyButton>
                  <BuyButton color="#fa7e1e">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="tel:+38349655866"
                    />
                    <BuyButtonIcon>
                      <Icon icon="tel" />
                    </BuyButtonIcon>
                    <BuyButtonParagraph>Telefon</BuyButtonParagraph>
                  </BuyButton>
                </Flex>
              </BuyOpptionsContainer>
            )}
          </Flex>
        </ProductDetails>
      </ProductLayout>
    </Flex>
  );
};

export default SingleProductHero;
