import React from "react";
import Image from "../image";
import { Flex } from "../../layouts";
import { Workcategory, Workcategorytitle } from "./layouts";

const Workcategories = (data) => {
  if (!data.data) return;
  const getRandomItems = (array, count) =>
    array.sort(() => Math.random() - 0.5).slice(0, count);

  const mixedCategories = getRandomItems(data.data, 4);

  return (
    <Flex>
      {mixedCategories &&
        mixedCategories.map((category) => {
          const {
            name = "",
            slug = "",
            categoryImageVertical = "",
            title = "Category Image",
          } = category;
          return (
            <Workcategory key={slug} to={`/category/${slug}`}>
              <Image
                source={categoryImageVertical.url}
                classList="p-absolute-fixed z-negative-1"
                alt={title}
              />
              <Workcategorytitle className="work-category-title">
                {name}
              </Workcategorytitle>
            </Workcategory>
          );
        })}
    </Flex>
  );
};

export default Workcategories;
