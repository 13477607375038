import styled from "@emotion/styled";
import {
  Colors,
  Fonts,
  Transitions,
} from "../../assets/variables/variables.js";

export const CategoryCard = styled.div`
  display: flex;
  position: relative;
  height: 450px;
  width: calc(15% - 10px);
  margin-right: 10px;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ${Transitions.$linearSmooth};

  img {
    z-index: -2;
  }

  p {
    width: 100%;
    text-shadow: 7px 6px 7px #000;
    color: #fff;
    opacity: 0;
    padding: 30px 20px;
    transform: translateY(13px);
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.5;
    z-index: -1;
  }

  &:first-of-type {
    border-radius: 7px 0 0 7px;

    &:before,
    img {
      border-radius: 7px 0 0 7px;
    }
  }

  &.active {
    border-radius: 7px;
    width: 25%;

    p {
      opacity: 1;
      transform: translateY(0);
      transition: 0.15s;
      transition-delay: 0.2s;
    }

    &:before {
      opacity: 0.2;
    }

    &:before,
    img {
      border-radius: 7px;
    }
  }
`;

export const CategoryCardHeader = styled.div`
  position: absolute;
  height: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 15px;
  align-items: center;
  left: 0;
  top: 0;

  h5,
  h3,
  p {
    color: #fff;
  }

  h3 {
    color: ${Colors.$primaryColor};
    font-family: ${Fonts.$medium};
    font-size: 32px;
  }

  h5 {
    line-height: 1;
    font-weight: 100;
    letter-spacing: 3px;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    font-family: ${Fonts.$medium};
  }
`;
