import { useEffect, useState } from "react";
import { Container, Flex } from "../../../layouts";
import Image from "../../image";
import { getQueryData } from "../../../config/data";
import { ImageContainer, RelatedProject } from "./layouts";
import { useParams } from "react-router-dom";
import Icon from "../../../assets/icons/icon";

const RelatedProjects = () => {
  const [projectData, setProjectData] = useState(null);
  const params = useParams();

  useEffect(() => {
    getQueryData({
      slug: params.slug,
      type: "relatedProjects",
      imagesLimit: 1,
      itemsLimit: 2,
    })
      .then((data) => {
        setProjectData(data.result.projectCollection.items);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [params]);

  if (!projectData) return;

  return (
    <Container>
      <Flex className="justify-between pb-50">
        {projectData.map((project, index) => {
          const { slug, title = "", imagesCollection = null } = project;
          const arrowIcon = index === 0 ? "leftArrowIcon" : "rightArrowIcon";

          return (
            <RelatedProject
              key={index}
              to={`/projects/${slug}`}
              direction={arrowIcon}
            >
              {imagesCollection && (
                <ImageContainer>
                  <Image source={imagesCollection.items[0].url} />
                  <Icon icon={arrowIcon} />
                </ImageContainer>
              )}
              <h4>{title}</h4>
            </RelatedProject>
          );
        })}
      </Flex>
    </Container>
  );
};

export default RelatedProjects;
