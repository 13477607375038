import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { Transitions } from "../../assets/variables/variables";

export const Workcategory = styled(Link)`
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  aspect-ratio: 1/1;
  overflow: hidden;

  img {
    transition: 0.2s ${Transitions.$superSmooth};
  }

  &:hover .work-category-title {
    background-color: rgba(255, 169, 21, 0.75);
    box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.8);
  }

  &:hover img {
    transform: scale(1.03);
  }
`;

export const Workcategorytitle = styled.span`
  width: 40%;
  height: 40%;
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-weight: bold;
  z-index: 1;
  transition: 0.3s ${Transitions.$superSmooth};
`;
