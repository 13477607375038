import React, { createContext, useContext, useState, useEffect } from "react";
import { getData, pageIds } from "../config/data";

const ExtrasContext = createContext();

export const ExtrasDataProvider = ({ children }) => {
  const [extrasData, setData] = useState(null);

  const config = {
    page: pageIds.extras,
    clear: true,
  };

  useEffect(() => {
    const fetchData = async () => {
      const { result, error } = await getData(config);
      if (error) {
        console.log("Error", error);
      }
      setData(result);
    };
    fetchData();
  }, []);

  return (
    <ExtrasContext.Provider
      value={{
        extrasData,
      }}
    >
      {children}
    </ExtrasContext.Provider>
  );
};

export const useExtrasData = () => {
  return useContext(ExtrasContext);
};
