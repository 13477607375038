import { useState, useEffect } from "react";
import { Container } from "../layouts";
import Product from "../components/product";
import { getQueryData } from "../config/data";
import { Title, WishListLayout, WishListProduct } from "../layouts/layouts";

const WishList = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const wishlistIds = JSON.parse(localStorage.getItem("wishlist")) || [];

    getWishlistProducts(wishlistIds).then((fetchedProducts) => {
      setProducts(fetchedProducts);
    });
  }, []);

  const getWishlistProducts = async (ids) => {
    const fetchedProducts = await getQueryData({
      type: "products",
      wishlishtIds: ids,
    });

    return fetchedProducts.result.productCollection.items;
  };

  return (
    <Container>
      <Title className="mb-50">Lista e produkteve tuaja të preferuara:</Title>
      <WishListLayout>
        {products &&
          products.map((product, index) => (
            <WishListProduct key={index}>
              <Product data={product} />
            </WishListProduct>
          ))}
      </WishListLayout>
    </Container>
  );
};

export default WishList;
