import styled from "@emotion/styled";

export const ModalConatiner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  top: 0;
  left: 0;

  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out;

  &.show {
    opacity: 1;
    visibility: visible;
  }
`;

export const Modal = styled.div`
  position: relative;
  display: flex;
  width: 900px;
  aspect-ratio: 16/9;
  border-radius: 8px;
  background-color: #fff;

  img {
    border-radius: 8px 0 0 8px;
  }
`;

export const SubscribeInput = styled.input`
  padding: 10px 50px 10px 25px;
  border-radius: 6px;
  background-color: #f0eced;
`;

export const ModalContent = styled.div`
  display: flex;
  width: 50%;
  padding: 100px 50px;
  padding-bottom: 150px;
  position: relative;
  flex-direction: column;
  justify-content: space-between;

  button {
    border-radius: 8px;
  }
`;

export const CloseBtnContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
`;
