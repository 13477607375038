import styled from "@emotion/styled";
import { Fonts } from "../../assets/variables/variables";

export const HeroContainer = styled.div`
  height: 100vh;
  width: 100%;
  position: relative;

  &:before {
    width: 100%;
    height: 200px;
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    background: linear-gradient(
      0deg,
      rgba(245, 238, 231, 1) 5%,
      rgba(245, 238, 231, 0.7) 30%,
      rgba(245, 238, 231, 0.5) 50%,
      rgba(245, 238, 231, 0.2) 70%,
      rgba(245, 238, 231, 0.05) 90%,
      rgba(245, 238, 231, 0) 100%
    );
  }
`;

export const HeroContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 200px 0;
  flex-direction: column;
  justify-content: space-between;

  h2 {
    font-size: 36px;
    flex: 0 0 40%;
  }

  P {
    flex: 0 0 60%;
    font-family: ${Fonts.$light};
  }

  h1,
  h2,
  p {
    color: #fff;
  }
`;

export const HeroImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;

  img {
    width: 100%;
    height: 100%;
  }
`;
