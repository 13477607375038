import styled from "@emotion/styled";
import { Colors, Fonts, Transitions } from "../assets/variables/variables";
import { motion } from "framer-motion";

export const FooterContainer = styled.div`
  background-color: ${Colors.$primaryColor};
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0 30px 0px;
`;

export const LogoContainer = styled.div`
  position: relative;
  width: 120px;
  height: 120px;
  margin-right: 20px;
`;

export const BigTag = styled.span`
  position: relative;
  font-size: 20px;
  line-height: 1.1;
  color: ${Colors.$tagColor};
  ${(props) => props.font && `font-family: "Poppins-` + props.font + `"`}};

`;

export const Tag = styled.span`
  position: relative;
  font-size: 16px;
  line-height: 1.1;
  color: ${Colors.$tagColor};
`;

export const BigTitle = styled(motion.h1)`
  font-size: 54px;
  line-height: 1.3;
  font-family: ${Fonts.$medium};
  ${(props) => props.font && `font-family: "Poppins-` + props.font + `"`}};
`;

export const Title = styled(motion.h2)`
  font-size: 32px;
  font-family: ${Fonts.$medium};
  ${(props) => props.letterSpace && `letter-spacing: ${props.letterSpace}px`}};
  ${(props) => props.font && `font-family: "Poppins-` + props.font + `"`}};

`;

export const SubTitle = styled.h2`
  font-size: 25px;
  ${(props) => (props.color ? `color: ${props.color}` : "")};
  ${(props) => props.font && `font-family: "Poppins-` + props.font + `"`}};
`;

export const Paragraph = styled(motion.p)`
  font-size: 18px;
  ${(props) => props.font && `font-family: "Poppins-` + props.font + `"`}};
`;

export const ProductTitle = styled.h3`
  display: inline-block;
  font-size: 18px;
  // color: #7c7c7c;
  color: #191919;
  padding: 0;
  line-height: 1;
  font-weight: normal;
  padding-bottom: 5px;
`;

export const ProductPrice = styled.p`
  display: inline-block;
  position: relative;
  font-size: 18px;
  color: #f5484a;
`;

export const Quote = styled.span`
  position: relative;
  font-size: 16px;
  padding-left: 10px;
  font-style: italic;
  line-height: 1;
  font-family: ${Fonts.$light};

  &:before {
    position: absolute;
    content: "";
    width: 2px;
    height: 100%;
    left: 0;
    top: 0;
    background: ${Colors.$primaryColor};
  }
`;

export const TitleExpresion = styled.h3`
  position: relative;
  padding-left: 50px;
  font-size: 32px;
  font-family: ${Fonts.$bold};
  text-transform: uppercase;
  color: ${Colors.$primaryColor};

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 40px;
    height: 2px;
    border-radius: 3px;
    background-color: ${Colors.$primaryColor};
    transform: translateY(-50%);
  }
`;

export const CategoriesLinkEffect = styled.p`
  padding: 5px;
  &:before {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${Colors.$primaryColor};
    transition: 0.2s;
  }

  &:hover {
    &:before {
      bottom: 3px;
    }
  }
`;

export const SingleProductImage = styled.div`
  position: relative;
  display: flex;
  height: 450px;
  text-align: center;
  justify-content: center;
  align-items: center;

  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: calc(100% - 50px);
    height: 100%;
    background: ${Colors.$primarySuperLightColor};
    border-radius: 0 1000px 1000px 0;
    z-index: -1;
  }
`;

export const MiniProductFeature = styled.div`
  position: relative;
  width: 70px;
  height: 70px;
  border: 1px solid black;
  padding: 5px;
  margin-right: 15px;
  cursor: pointer;
  transition: 0.1s;

  &:hover {
    background: ${Colors.$primaryLightColor};
  }
`;

export const SubmitButton = styled.button`
  font-size: 15px;
  cursor: pointer;
  background-color: ${Colors.$primaryLightColor};
  color: #fff;
  border: none;
  border-radius: 25px;
  padding: 15px 40px 15px 40px;
  border: 1px solid ${Colors.$primaryLightColor};
  color: #000;
  font-family: ${Fonts.$medium};
  transition: 0.4s ${Transitions.$superSmooth};

  &:hover {
    background-color: #fff;
    color: ${Colors.$primaryColor};
    border: 1px solid ${Colors.$colorGreyStandart};
  }
`;

export const WishListLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
`;

export const WishListProduct = styled.div`
  width: 250px;
`;

export const Hotsaletag = styled.div`
  top: -20px;
  right: -26px;
  color: #fff;
  padding: 15px;
  height: 40px;
  display: flex;
  min-width: 170px;
  font-weight: bold;
  position: absolute;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border-radius: 12px 12px 0px;
  background-color: ${Colors.$primaryColor};

  &:before {
    content: "";
    position: absolute;
    bottom: -26px;
    right: 0;
    border-top: 14px solid ${Colors.$primaryDarkColor};
    border-left: 13px solid ${Colors.$primaryDarkColor};
    border-bottom: 13px solid transparent;
    border-right: 13px solid transparent;
  }

  p {
    font-size: 18px;
    font-weight: 500;
    padding-right: 7px;
    font-family: ${Fonts.$bold};

    &:last-of-type {
      font-family: ${Fonts.$regular};
    }
  }
`;
