import React from "react";
import { Container } from "../../layouts";
import Logo from "../../components/logo";
import Navigation from "../navigation";
import { HeaderContainer } from "../../layouts/layouts";
import { Helmet } from "react-helmet";

const Header = () => {
  const pageTitle = "AS Welder";
  const pageDescription =
    "Welcome to our welding company, specializing in high-quality professional work with wood and metal. We are dedicated to turning your dreams and ideas into reality. Our skilled team of welders and craftsmen meticulously fabricates and welds to bring your projects to life. Whether you need custom metalwork, artistic designs, or structural welding, we have the expertise and passion to deliver outstanding results. Contact us today to transform your vision into tangible creations.";

  const pageKeywords = [
    "Welding services",
    "Metal fabrication",
    "Custom metalwork",
    "Structural welding",
    "Interior design",
    "Welding company",
    "Wood and metal welding",
    "Exterior design",
    "Professional welders",
    "High-quality welding",
  ];

  const ogImageUrl =
    "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.facebook.com%2Fas.welder%2F&psig=AOvVaw2tB6dw2eEgbnQc1L44PCrM&ust=1687890944794000&source=images&cd=vfe&ved=0CBEQjRxqFwoTCID_lvPJ4f8CFQAAAAAdAAAAABAE";

  return (
    <div className="background-grey p-relative w-full">
      <Container>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <meta name="keywords" content={pageKeywords.join(",")} />
          <link rel="canonical" href="http://yourwebsite.com/page-url" />

          {/* Open Graph */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:description" content={pageDescription} />
          <meta property="og:image" content={ogImageUrl} />
          <meta property="og:url" content="http://yourwebsite.com/page-url" />
          <meta property="og:type" content="website" />

          {/* Twitter Card */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDescription} />
          <meta name="twitter:image" content={ogImageUrl} />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <HeaderContainer>
          <Logo />
          <Navigation />
        </HeaderContainer>
      </Container>
    </div>
  );
};

export default Header;
