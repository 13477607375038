import { NavLink } from "react-router-dom";
import aswelderLogo from "../../assets/images/ASWelderLogo.png";
import { LogoContainer } from "../../layouts/layouts";
import Image from "../image";

const Logo = ({ className }) => {
  return (
    <LogoContainer className={className}>
      <NavLink to="/" className={"d-flex p-relative"}>
        <Image
          source={aswelderLogo}
          classList={"w-full h-full object-contain"}
          alt="AS-Welder-logo"
        />
      </NavLink>
    </LogoContainer>
  );
};

export default Logo;
