import React, { useEffect, useState } from "react";
import Subscribe from "../components/subscribe";
import DynamicComponent from "../components/dynamic-component";
import { getData, pageIds } from "../config/data";

const Home = () => {
  const [data, setData] = useState(null);

  const config = {
    page: pageIds.homepage,
    clear: true,
  };

  useEffect(() => {
    const fetchData = async () => {
      const { result, error } = await getData(config);
      if (error) {
        console.log("Error", error);
      }
      setData(result);
    };
    fetchData();
  }, []);

  if (!data) return;

  return (
    <div className="mt-70">
      <DynamicComponent listOfComponentsType={data.posts} />
      <Subscribe />
    </div>
  );
};

export default Home;
