import React, { useEffect, useState } from "react";
import HeroSimple from "../components/hero-simple";
import DynamicComponent from "../components/dynamic-component";
import { getData, pageIds } from "../config/data";

const About = () => {
  const [data, setData] = useState(null);
  const [pageHeaderData, setPageHeaderData] = useState(null);

  const config = {
    page: pageIds.about,
    clear: true,
  };

  useEffect(() => {
    const fetchData = async () => {
      const { result, error } = await getData(config);

      // TODO: Handle the error here if needed.
      if (error) {
        console.error("Error:", error);
        // Handle the error as needed
      }
      const pageData = {
        title: result.page,
        image: result.headerImage.fields.file,
      };

      setData(result);
      setPageHeaderData(pageData);
    };

    fetchData();
  }, []);

  if (!data) return;

  return (
    <>
      <HeroSimple data={pageHeaderData} />
      <DynamicComponent listOfComponentsType={data.posts} />
    </>
  );
};

export default About;
