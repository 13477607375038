import React from "react";
import { BtnContainer, Btn, SecondaryBtn } from "./layouts";
import { Link } from "react-router-dom";

const Button = ({
  text = "Button",
  to,
  style,
  parentSyle,
  secondary,
  anime,
}) => {
  const ButtonFunction = ({ className, children }) => {
    if (to) {
      return (
        <Link to={to} className={className}>
          {children}
        </Link>
      );
    }
    return <Btn className={className}>{children}</Btn>;
  };

  const ButtonContent = () => {
    return (
      <>
        {secondary ? (
          <SecondaryBtn className={`w-full cursor-pointer font-bold ${style}`}>
            {text}
          </SecondaryBtn>
        ) : (
          <ButtonFunction>
            <Btn className={`w-full cursor-pointer font-bold ${style}`}>
              {text}
            </Btn>
          </ButtonFunction>
        )}
      </>
    );
  };

  return anime ? (
    <BtnContainer
      className={`${parentSyle}`}
      variants={anime}
      initial="initial"
      whileInView="animate"
      viewport={anime.viewport}
      transition={anime.transition}
    >
      {ButtonContent()}
    </BtnContainer>
  ) : (
    <BtnContainer className={`${parentSyle}`}>{ButtonContent()}</BtnContainer>
  );
};

export default Button;
