import { useEffect, useState } from "react";
import { Container, Flex, Row } from "../../layouts";
import { Title } from "../../layouts/layouts";
import Product from "../product";
import { RelatedProductsContainer } from "./layouts";

const RelatedProducts = ({ data }) => {
  const [relatedData, setRelatedData] = useState([]);

  useEffect(() => {
    if (data) {
      setRelatedData(data);
    }
  }, [data]);

  if (!data) return;

  return (
    <RelatedProductsContainer>
      <Container>
        <Flex className="flex-column mb-50 mb-md-30">
          {/* {extrasData && <Title>{extrasData.relatedProductsTitle}</Title>} */}
          <Title>Related Products</Title>
        </Flex>
        {data && (
          <Row className="w-full mr-0 ml-0">
            {relatedData.map((product, index) => {
              return (
                <Product
                  data={product}
                  key={index}
                  size="col-6 col-lg-3 col-xl-3"
                />
              );
            })}
          </Row>
        )}
      </Container>
    </RelatedProductsContainer>
  );
};

export default RelatedProducts;
