import styled from "@emotion/styled";
import { Colors, Fonts, Transitions } from "../../assets/variables/variables";

export const PaginateNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  padding: 5px 10px;
  margin: 0;
  line-height: 1;
  cursor: pointer;
  font-size: 18px;
  user-select: none;
  color: ${Colors.grey};
  font-family: ${Fonts.$medium};

  span {
    transition: 0.2s ${Transitions.$superSmooth};
  }

  &:hover {
    span,
    button {
      color: ${Colors.$primaryColor};
    }
  }

  span {
    display: inline-block;
    position: relative;
    font-weight: bold;
  }

  &.active {
    height: calc(100% + 4px);
    margin-top: -2px;
    min-width: 40px;
    border-radius: 8px;
    color: #fff;
    background-color: ${Colors.$primaryColor};
    box-shadow: 0px 0px 7px 3px rgb(255 169 21 / 46%);

    span {
      color: ${Colors.black};
    }

    &:hover {
      span {
        color: #fff;
      }
    }
  }
`;

export const PrevNext = styled.button`
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;

  &:hover {
    &:after,
    &:before {
      background-color: ${Colors.$primaryColor};
    }
  }

  &:after,
  &:before {
    content: "";
    position: absolute;
    height: 2px;
    width: 8px;
    top: calc(50% + 2px);
    left: 50%;
    background-color: #000;
    transition: 0.2s ${Transitions.$linearSmooth};
  }

  &:after {
    top: calc(50% - 3px);
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &.prev {
    &:after {
      top: calc(50% - 3px);
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
`;

export const PaginationNumberContainer = styled.div`
  display: flex;
  padding: 0px 10px;
  height: 35px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 50px;
    background-color: #f0f0f0;
  }
`;

export const PaginationButtons = styled.div`
  background-color: #f0f0f0;
  position: relative;
  border-radius: 100%;
  min-width: 34px;
  cursor: pointer;
  margin-right: 5px;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
