import styled from "@emotion/styled";
import { Transitions } from "../../../assets/variables/variables";
import { Link } from "react-router-dom";

export const RelatedProject = styled(Link)`
  position: relative;
  width: calc(50% - 100px);
  margin: 0 10px;

  img {
    width: 100%;
    height: 100%;
  }

  > div {
    opacity: 0.9;
  }

  svg {
    position: absolute;
    top: 50%;
    left: -31px;
    width: 30px;
    height: 100px;
    transform: translateY(-50%);
    transition: 0.4s ${Transitions.$superSmooth};
  }

  ${(props) =>
    props.direction === "rightArrowIcon" &&
    "svg { left: initial; right: -31px }"};

  &:hover {
    > div {
      opacity: 1;

      &:before {
        opacity: 0;
      }
    }

    svg {
      ${(props) =>
        props.direction === "rightArrowIcon" ? "right: -40px" : "left: -40px"};
    }
  }
`;

export const ImageContainer = styled.div`
  opacity: 0.8;
  cursor: pointer;
  position: relative;
  aspect-ratio: 16/9;
  transition: 0.4s ${Transitions.$superSmooth};

  img {
    display: flex;
    margin-bottom: 10px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background-color: #fff;
    transition: 0.4s ${Transitions.$superSmooth};
  }
`;
