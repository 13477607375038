import styled from "@emotion/styled";

const Construction = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #e69100;
  z-index: 9999999999;

  h2 {
    color: #000;
    font-size: 20px;
  }
`;

const UnderConstruction = () => {
  return (
    <Construction>
      <h2>The site is still under construction. Jemi duke punuar ende.</h2>
    </Construction>
  );
};

export default UnderConstruction;
