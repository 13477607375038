import React, { useState, useEffect } from "react";
import ProductList from "../components/products";
import Workcategories from "../components/work-categories";
import { Container } from "../layouts";
import { getQueryData } from "../config/data";
import { useLocation } from "react-router-dom";

const Products = () => {
  // const [pageActive, setPageActive] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const urlSelectedCategories = params.get("categories");
  const minPriceParam = params.get("minPrice");
  const maxPriceParam = params.get("maxPrice");
  const sortingParam = params.get("sort");
  const paginateNumer = params.get("page");
  const [products, setProducts] = useState(null);
  const [categories, setCategories] = useState(null);
  const [minMaxPrice, setMinMaxPrice] = useState(null);
  const [mixedCategories, setMixedCateories] = useState(null);
  const [minPrice] = useState(minPriceParam ? Number(minPriceParam) : null);
  const [maxPrice] = useState(maxPriceParam ? Number(maxPriceParam) : null);
  const [selectedCategories] = useState(
    urlSelectedCategories ? urlSelectedCategories.split(",") : null
  );
  const sortTypes = {
    priceASC: "price_ASC",
    priceDESC: "price_DESC",
    dateASC: "sys_publishedAt_ASC",
    dateDESC: "sys_publishedAt_DESC",
  };
  const [sort] = useState(sortingParam ? sortTypes[sortingParam] : null);

  useEffect(() => {
    getQueryData({
      type: "minmaxprice",
    }).then((data) => {
      const result = data.result;

      if (result) {
        setMinMaxPrice({
          maxPrice: result.maxPrice.items[0].price,
          minPrice: result.minPrice.items[0].price,
        });
      }
    });

    // if (!products) {
    if (!selectedCategories && !minPrice && !maxPrice && !sort) {
      getQueryData({ type: "products", limit: 300 })
        .then((data) => {
          if (data.result) {
            const result = data.result.productCollection.items;
            setProducts(result);
          }
        })
        .catch((error) => {
          console.error("Error: 'e903'", error);
        });
    } else {
      getQueryData({
        type: "products",
        categorySlug: selectedCategories,
        minPrice: minPrice,
        maxPrice: maxPrice,
        sort,
      })
        .then((data) => {
          const result = data.result.productCollection.items;
          setProducts(result);
        })
        .catch((error) => {
          console.error("Error: 'e903'", error);
        });
    }
    getQueryData({ type: "mixedCategories" })
      .then((data) => {
        setMixedCateories(data.result.categoryCollection.items);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    getQueryData({ type: "categories" })
      .then((data) => {
        setCategories(data.result.categoryCollection.items);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    // }
  }, []);

  return (
    <>
      <Container>
        {products && (
          <ProductList
            data={products}
            container={true}
            sortParam={sort}
            activeFilters={true}
            minMaxPrice={minMaxPrice}
            paginateNumer={paginateNumer}
            categories={categories}
            priceValuesParam={{ minPrice: minPrice, maxPrice: maxPrice }}
            selectedCategoriesParam={selectedCategories}
          />
        )}
      </Container>
      {mixedCategories && <Workcategories data={mixedCategories} />}
    </>
  );
};

export default Products;
