import DeliveryIcon from "../../assets/images/deliveryIcon.png";
import { Paragraph, Hotsaletag } from "../../layouts/layouts";
import { Title } from "../../layouts/layouts";
import Image from "../image";
import { useRef } from "react";
import { useScroll, useSpring, useTransform } from "framer-motion";
import {
  HotsaleBtn,
  Hotsalecard,
  HotSaleContainer,
  HotsalePrevPrice,
} from "./layouts";

const Card = ({ data, key, reverse }) => {
  const {
    quote = "",
    image = null,
    subtitle = "",
    description = "",
    title = "The title",
  } = data.fields;

  const ref = useRef();

  const { scrollYProgress } = useScroll({
    target: ref,
    smooth: 10000,
    offset: ["0 1", "1 1"],
  });

  const smoothProgres = useSpring(scrollYProgress, {
    mass: 1.5,
    damping: 20,
    stiffness: 150,
    restDelta: 0.001,
  });

  const rotateTransform = useTransform(
    smoothProgres,
    [0.2, 1],
    reverse ? ["85.8deg", "0deg"] : ["-85.8deg", "0deg"]
  );

  const moveTransform = useTransform(
    smoothProgres,
    [0.2, 1],
    reverse ? ["75px", "0px"] : ["-75px", "0px"]
  );

  return (
    <HotSaleContainer>
      <Hotsalecard
        key={key}
        ref={ref}
        style={{ rotateY: rotateTransform, x: moveTransform }}
      >
        <Hotsaletag>
          <p>{subtitle}</p>
          <p>{quote}</p>
        </Hotsaletag>
        <Image
          source={image?.fields.file.url}
          alt={image.fields.file.fileName}
          classList="object-cover pb-20 w-full h-full"
        />
        <div className="d-flex flex-column align-center w-full px-20 pb-10">
          <Paragraph className="pb-5">{title}</Paragraph>
          <Title className="pb-10">
            {description}
            <HotsalePrevPrice>50€</HotsalePrevPrice>
          </Title>
        </div>
        <HotsaleBtn to="/">
          <p>BLEJ TANI</p>
          <Image source={DeliveryIcon} alt={"order-image"} />
        </HotsaleBtn>
      </Hotsalecard>
    </HotSaleContainer>
  );
};

export default Card;
