import {
  Colors,
  Fonts,
  Transitions,
} from "../../assets/variables/variables.js";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { motion } from "framer-motion";

export const Summersalebtn = styled(motion.p)`
  position: relative;
  color: ${Colors.$primaryColor};
  text-decoration: none;
  padding: 5px;
  font-weight: bold;

  &:before,
  &:after {
    content: "";
    position: absolute;
    bottom: -3px;
    background-color: ${Colors.$primaryColor};
    transition: 0.3s cubic-bezier(0.25, 1, 0.5, 1);
  }

  &:before {
    width: 100%;
    height: 2px;
    right: 0;
  }

  &:after {
    right: -5px;
    width: 2px;
    height: 0;
    top: 0;
    transition-delay: 0.1s;
  }

  &:hover {
    &:before {
      width: calc(100% - 10px);
      right: -5px;
    }

    &:after {
      height: calc(100% + 3px);
      transition-delay: 0;
    }
  }
`;

export const HotSaleContainer = styled.div`
  perspective: 1000px;
  width: calc(50% - 50px);
  margin-right: 50px;
`;

export const Hotsalecard = styled(motion.div)`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 10px 35px 10px;
  border-radius: 6px;
  box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  transform-style: preserve-3d;

  img {
    aspect-ratio: 1/1;
  }

  h2 {
    display: flex;
    align-items: center;
  }
`;

export const Hotsalebackground = styled(motion.div)`
  position: absolute;
  right: 0;
  width: 50%;
  height: 100%;
  z-index: -1;

  img {
    opacity: 0.15;
    padding-top: 100px;
    object-fit: contain;
  }
`;

export const HotsaleBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 9px 25px;
  min-width: 175px;
  min-height: 42px;
  text-align: center;
  color: #fff;
  letter-spacing: 1.3px;
  border-radius: 6px;
  font-family: ${Fonts.$medium};
  background-color: ${Colors.$primaryColor};
  box-shadow: rgba(255, 169, 21, 0.19) 0px 10px 20px,
    rgba(255, 169, 21, 0.23) 0px 6px 6px;
  transition: 0.4s ${Transitions.$linearSmooth};
  overflow: hidden;

  p {
    position: relative;
    display: inline-block;
    transition: 0.4s ${Transitions.$linearSmooth};

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: -13px;
      height: 2px;
      background-color: #000;
      transofrm: translateY(-50%);
      width: 3px;
      opacity: 0;
      transition: 0.25s ${Transitions.$linearSmooth};
    }
  }

  img {
    width: 38px;
    height: 38px;
    position: absolute;
    top: 0;
    right: -40px;
    transition: 0.6s ${Transitions.$superSmooth};
  }

  &:hover {
    ${(props) =>
      props.type === "secondary"
        ? css`
            p {
              color: #000;

              &:before {
                width: 10px;
                opacity: 1;
              }
            }
          `
        : "padding: 7px 45px 7px 5px"};

    img {
      transition-delay: 0.2s;
      right: 15px;
    }
  }

  &:focus {
    img {
      right: -40px;
    }
  }
`;

export const HotsalePrevPrice = styled.p`
  font-size: 20px;
  font-weight: 100;
  padding-left: 10px;
  text-decoration: line-through;
  color: ${Colors.$primaryMediumColor};
`;
