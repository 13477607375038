import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import Image from "../components/image";
import welcomeimagesrc from "../assets/images/welcome.webp";
import { Flex } from "../layouts";

const CommingsoonContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  display: fixed;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

const CommingSoonContent = styled.div`
  max-width: 70%;
  z-index: 3;

  h1,
  h3 {
    color: #fff;
  }
`;

const ClockItem = styled.div`
  margin: 0 25px;
  width: 140px;
  height: 140px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;

  h2 {
    line-height: 1.2;
  }

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid #fff;
    border-radius: 15px;
    transform: rotate(45deg);
  }
`;

const ClockItems = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const CommingSoonImage = styled.div`
  width: 100%;
  height: 100%;

  &:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgb(0 0 0 / 56%);
    z-index: 1;
  }
`;

const Commingsoonpage = () => {
  const targetDate = new Date();
  targetDate.setMonth(5); // June is represented by index 5
  targetDate.setDate(22); // Set the target day to 20

  const [time, setTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [isLoaded, setIsLoaded] = useState(false);

  const calculateTimeRemaining = () => {
    const currentTime = new Date();
    const timeRemaining = Math.max(targetDate - currentTime, 0);

    const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeRemaining / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((timeRemaining / (1000 * 60)) % 60);
    const seconds = Math.floor((timeRemaining / 1000) % 60);

    return { days, hours, minutes, seconds };
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <>
      <CommingSoonImage>
        <Image
          source={welcomeimagesrc}
          classList="w-full h-full object-position-b p-absolute"
          alt={"coming-soon-image"}
        />
      </CommingSoonImage>
      <CommingsoonContainer>
        <CommingSoonContent>
          <Flex className="flex-column align-center mb-50">
            <h1>We are comming soon!!!</h1>
            <h3>Stay tuned for something amazing...</h3>
          </Flex>
          <ClockItems>
            <ClockItem>
              <h2>{time.days}</h2>
              <span>Days</span>
            </ClockItem>
            <ClockItem>
              <h2>{time.hours}</h2>
              <span>Hrs</span>
            </ClockItem>
            <ClockItem>
              <h2>{time.minutes}</h2>
              <span>Mins</span>
            </ClockItem>
            <ClockItem>
              <h2>{time.seconds}</h2>
              <span>Seconds</span>
            </ClockItem>
          </ClockItems>
        </CommingSoonContent>
      </CommingsoonContainer>
    </>
  );
};

export default Commingsoonpage;
