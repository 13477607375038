import { useState } from "react";
import { Block } from "../../layouts";
import RenderText from "../render-text";
import { ContentContainer, TabsContainer, DetailTab, Content } from "./layouts";

const SingleProductDetails = ({ data }) => {
  const [activeModule, setActiveModule] = useState({
    type: "description",
    id: 0,
  });

  const detailsButtons = [
    {
      active: data.description && true,
      type: "description",
      title: "Përshkrimi",
    },
    {
      active: data.specifications && true,
      type: "specification",
      title: "Specifikat",
    },
  ];

  function handleDetailsTabs(type, index) {
    setActiveModule({ type: type, id: index });
  }

  return (
    <Block className="mb-70 w-full">
      <ContentContainer>
        <TabsContainer>
          {detailsButtons &&
            detailsButtons.map((button, index) => {
              const {
                type = null,
                title = "Write a title",
                active = false,
              } = button;

              return active ? (
                <DetailTab
                  key={index}
                  className={`${index === activeModule.id ? "active" : ""}`}
                  onClick={() => handleDetailsTabs(type, index)}
                >
                  {title}
                </DetailTab>
              ) : null;
            })}
        </TabsContainer>
        <Content>
          {data.description &&
            activeModule.type === "description" &&
            RenderText(data.description)}

          {data.specifications &&
            activeModule.type === "specification" &&
            RenderText(data.specifications)}
        </Content>
      </ContentContainer>
    </Block>
  );
};

export default SingleProductDetails;
