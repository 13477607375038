import { useEffect, useState } from "react";
import { Flex } from "../../layouts";
import Project from "./project";

const OurProjects = (data) => {
  const [projects, setProjects] = useState(null);

  useEffect(() => {
    if (data) {
      setProjects(data.data.items);
    }
  }, [data]);

  if (!projects) return;

  return (
    <Flex className="mt-70 flex-column">
      {projects &&
        projects.map((project, index) => {
          return <Project key={index} data={project} />;
        })}
    </Flex>
  );
};

export default OurProjects;
