import Image from "../image";
import { Container } from "../../layouts";
import { BigTitle, BigTag, Paragraph } from "../../layouts/layouts";
import { Categoryherocontent } from "./layouts";

const CategoryHero = ({ data }) => {
  const {
    slug = null,
    saleTag = null,
    description = "",
    categoryImageHorizontal = null,
  } = data;

  return (
    <div className="p-relative min-height-350 w-full d-inline-block">
      {categoryImageHorizontal && (
        <Image
          source={categoryImageHorizontal.url}
          classList=" p-absolute-fixed p-fixed index-negative-1"
          alt={categoryImageHorizontal.fileName}
        />
      )}
      <Container>
        <Categoryherocontent>
          {saleTag && <BigTag className="font-bold pb-10">{saleTag}</BigTag>}
          <BigTitle className="pb-20">{slug.toUpperCase()}</BigTitle>
          <Paragraph className="pb-30">{description}</Paragraph>
        </Categoryherocontent>
      </Container>
    </div>
  );
};

export default CategoryHero;
