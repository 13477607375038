import { cubicBezier } from "framer-motion";

export const titleAnimation = {
  initial: {
    y: "150%",
    scaleX: 0.8,
  },
  animate: {
    y: "0",
    scaleX: 1,
  },
  viewport: {
    once: false,
    margin: "0px  0px 200px 0px",
  },
  transition: {
    duration: 0.9,
    ease: "backInOut",
    delay: 0.3,
  },
};

export const textAnimation = {
  initial: {
    y: "150%",
    scaleX: 0.8,
  },
  animate: {
    y: "0",
    scaleX: 1,
  },
  viewport: {
    once: false,
    margin: "0px  0px 200px 0px",
  },
  transition: {
    duration: 1.2,
    ease: "backInOut",
    delay: 0.5,
  },
};

// export const serviceAnimation = {
//   initial: {
//     x: "100px",
//     opacity: 0,
//   },
//   animate: {
//     x: "0",
//     opacity: 1,
//   },
//   viewport: {
//     once: true,
//     amount: 0.11,
//   },
// };

export const serviceAnimation = {
  initial: {
    x: "100px",
    opacity: 0,
  },
  animate: (index) => ({
    x: "0",
    opacity: 1,
    transition: {
      type: "spring",
      duration: 0.4,
      delay: index * 0.13,
    },
  }),
  viewport: {
    once: true,
    amount: 0.1,
  },
};
