import styled from "@emotion/styled";
import {
  Colors,
  Fonts,
  Transitions,
} from "../../assets/variables/variables.js";

export const InfoItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 50px;
  width: 100%;

  svg {
    width: 25px;
    height: 25px;
    margin-right: 15px;
    margin-top: 5px;
  }

  a {
    display: flex;

    svg {
      width: 35px;
      height: 35px;
      margin: 0;
      margin-right: 10px;
    }
  }
`;

export const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #f8f9fa;
  padding: 50px;
`;

export const InputText = styled.input`
  border-radius: 5px;
  outline: none;
  border: 1px solid ${Colors.$colorGreyStandart};
  padding: 25px;
  width: 500px;
  font-size: 18px;
  margin-bottom: 15px;
  font-family: ${Fonts.$light};
`;

export const InputMessage = styled.textarea`
  border-radius: 5px;
  max-width: 500px;
  min-width: 500px;
  min-height: 150px;
  max-height: 150px;
  padding: 10px 0px 10px 10px;
  outline: none;
  border: 1px solid ${Colors.$colorGreyStandart};
  padding: 25px;
  height: 150px;
  font-size: 18px;
  margin-bottom: 15px;
  font-family: ${Fonts.$light};
`;
