import styled from "@emotion/styled";
import { Colors, Fonts, Transitions } from "../../assets/variables/variables";
import { Link } from "react-router-dom";

export const ProductLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding-top: 30px;
`;

export const ImagesGallery = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 25px;
  gap: 15px;
`;

export const ProductDetails = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

//===========================================

export const MainImageWrapper = styled.div`
  background-color: #f1f1f1;
  height: 500px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    height: 100%;
    max-width: 100%;
  }
`;

export const ThumbnailGalleryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  width: calc(33.333% * 3);
  max-width: 100%;
  margin: 0 auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const ThumbnailImageWrapper = styled.div`
  width: calc(33.333% - 10px);
  height: 150px;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #f1f1f1;
  border: ${({ isActive }) => (isActive ? "3px solid #FFA915" : "none")};
  flex: 0 0 auto;
  scroll-snap-align: start;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  &:last-child {
    margin-right: 0;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const ProductTitle = styled.h1`
  font-size: 36px;
  font-weight: bold;
  line-height: 1.2;
  margin-top: 5px;
`;

export const PricingSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const OriginalPrice = styled.p`
  font-size: 24px;
  color: #ff0000;
  text-decoration: line-through;
  margin-right: 10px;
  opacity: 0.6;
`;

export const DiscountedPrice = styled.p`
  font-size: 24px;
  font-weight: bold;
`;

export const BreadcrumbsNav = styled(Link)`
  font-size: 14px;
  color: #666;
  margin-right: 5px;

  &:hover {
    color: ${Colors.$primaryColor};
  }
`;

export const MixedCategory = styled.div`
  margin-top: 20px;
`;

export const CategoryLink = styled(Link)`
  font-size: 16px;
  color: ${Colors.$primaryColor};
  font-weight: bold;
  margin-right: 5px;
  padding-right: 10px;

  &:hover {
    color: ${Colors.$colorGrey};
  }
`;

export const SeperatorLine = styled.span`
  height: 1px;
  background-color: #ccc;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
`;

//--------------------------------

export const Slider = styled.div`
  position: relative;
  width: 100%;
`;

export const BuyParagraph = styled.p`
  margin-bottom: 10px;
  font-size: 16px;
  color: #000;
  opacity: 0.8;
`;

export const BuyOpptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  opacity: 0;
  transform: translateY(-30px);
  transition: 0.3s ${Transitions.$superSmooth};
  visibility: hidden;

  &.active {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
`;

export const BuyButton = styled.div`
  outline: none;
  border: none;
  ${(props) => (props.type === "buy" ? "padding: 8px 35px;" : "width: 170px")};
  display: flex;
  align-items: center;
  position: relative;
  color: #fff;
  letter-spacing: 1px;
  font-weight: 300;
  border-radius: 6px;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: ${(props) =>
    props.color ? props.color : Colors.$colorBuyBtn};

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    box-shadow: 4px 4px 9px 0px
      ${(props) => (props.color ? props.color : Colors.$colorBuyBtn)};
    opacity: 0.6;
    z-index: -1;
    transition: 0.2s ${Transitions.$linearSmooth};
  }

  svg {
    width: 30px;
    height: 30px;
  }

  &:hover {
    &:before {
      opacity: 1;
    }
  }

  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
`;

export const BuyButtonIcon = styled.div`
  padding: 8px 0;
  margin: 0 15px;
  display: flex;
  align-items: center;
  width: 30px; 
  height: 100%;
  border-radius: 6px; 0 0 6px;
`;

export const BuyButtonParagraph = styled.p`
  width: calc(100% - 61px);
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  color: #000;
  position: relative;
  font-size: 16px;
  border-radius: 0 6px 6px 0;
`;

export const MainBuyButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 9px 25px;
  min-width: 175px;
  min-height: 42px;
  text-align: center;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  letter-spacing: 1.3px;
  border-radius: 6px;
  background-color: ${Colors.$primaryColor};
  box-shadow: rgba(255, 169, 21, 0.19) 0px 10px 20px,
    rgba(255, 169, 21, 0.23) 0px 6px 6px;
  transition: 0.4s ${Transitions.$linearSmooth};
  overflow: hidden;

  p {
    position: relative;
    display: inline-block;
  }

  img {
    width: 38px;
    height: 38px;
    position: absolute;
    top: 0;
    right: -40px;
    transition: 0.6s ${Transitions.$superSmooth};
  }

  &:hover {
    padding: 7px 45px 7px 5px;

    img {
      transition-delay: 0.2s;
      right: 15px;
    }
  }

  &:focus {
    img {
      right: -40px;
    }
  }
`;

export const ProductCode = styled.p`
  font-family: ${Fonts.$light};
  font-size: 16px;
`;
