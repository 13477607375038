import React, { useEffect, useState } from "react";
import CategoryHero from "../components/category-hero";
import ProductList from "../components/products";
import { Container } from "../layouts";
import { getQueryData } from "../config/data";

const Category = () => {
  const [data, setData] = useState(null);
  const [categoryData, setCategoryData] = useState(null);

  const currentPath = window.location.pathname.split("category/")[1];

  useEffect(() => {
    getQueryData({ type: "singleCategory", categorySlug: currentPath })
      .then((data) => {
        setCategoryData(data.result.categoryCollection.items[0]);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    getQueryData({ type: "categoryProducts", categorySlug: currentPath })
      .then((data) => {
        setData(data.result.productCollection.items);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  if (!data) return;

  return (
    <>
      {categoryData && <CategoryHero data={categoryData} />}
      {data && (
        <div className="p-relative background-w">
          <Container className="pt-70">
            <ProductList data={data} activeFilters={false} />
          </Container>
        </div>
      )}
    </>
  );
};

export default Category;
