import React, { useState } from "react";
import { Flex } from "../../layouts";
import { Title } from "../../layouts/layouts";
import { CategoryCard, CategoryCardHeader } from "./layouts";

const OurApproaches = (data) => {
  const [aboutData, setAboutData] = useState(null);
  const [activeCat, setActiveCat] = useState(0);

  useState(() => {
    if (data) {
      setAboutData(data.fields);
    }
  }, []);

  function changeCard(e) {
    setActiveCat(e);
  }

  if (!aboutData) return;

  return (
    <Flex className="flex-column align-center mb-70">
      <Title className="py-70">{aboutData.title}</Title>
      <Flex className="w-full">
        {aboutData.approach &&
          aboutData.approach.map((card, index) => {
            const {
              image = null,
              title = "Title",
              description = "",
            } = card.fields;

            const imageUrl = image && image.fields.file.url;

            return (
              <CategoryCard
                key={index}
                onClick={() => changeCard(index)}
                className={`${activeCat == index ? "active" : ""}`}
              >
                <img
                  src={imageUrl}
                  className="p-absolute-fixed object-cover"
                  alt="Approach pic"
                />
                <CategoryCardHeader>
                  <h3>{index + 1}</h3>
                  <h5>{title}</h5>
                </CategoryCardHeader>
                <p>{description}</p>
              </CategoryCard>
            );
          })}
      </Flex>
    </Flex>
  );
};

export default OurApproaches;
