import { client } from "./client";
import {
  projectsQuery,
  productsQuery,
  singleCategory,
  categoriesQuery,
  minmaxpriceQuery,
  mixedCategoriesQuery,
  categoryProductsQuery,
  relatedProjectsQuery,
} from "./queries";

export const getData = (config) => {
  const { page = null, clear = false } = config;

  const defaultConfig = {
    "sys.id": page,
    include: 10,
    limit: 200,
  };

  const entryCall = page
    ? client.getEntries(defaultConfig)
    : client.getEntries(config);

  return new Promise((resolve) => {
    entryCall
      .then((response) => {
        const data = clear
          ? response.items[0].fields
          : page
          ? response.items[0].fields.posts
          : response.items[0];
        resolve({ result: data, error: null });
      })
      .catch((error) => {
        resolve({ result: null, error: error });
      });
  });
};

export const getQueryData = (config) => {
  return new Promise((resolve, reject) => {
    const spaceId = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
    const accessToken = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;
    const apiUrl = `https://graphql.contentful.com/content/v1/spaces/${spaceId}`;

    const {
      slug,
      sort,
      skip = 1,
      minPrice,
      maxPrice,
      searchTerm,
      categorySlug,
      imagesLimit = 1,
      wishlishtIds = null,
      currentProductSlug,
      itemsLimit = 400,
      type = "products",
    } = config;

    const queryTypes = {
      minmaxprice: { query: minmaxpriceQuery },
      categories: { query: categoriesQuery },
      mixedCategories: { query: mixedCategoriesQuery },
      singleCategory: { query: singleCategory, variables: { categorySlug } },
      products: {
        query: productsQuery,
        variables: {
          slug,
          sort,
          minPrice,
          maxPrice,
          searchTerm,
          itemsLimit,
          imagesLimit,
          categorySlug,
          wishlishtIds,
        },
      },
      categoryProducts: {
        query: categoryProductsQuery,
        variables: { categorySlug, itemsLimit, skip, currentProductSlug },
      },
      projects: {
        query: projectsQuery,
        variables: { slug, imagesLimit, itemsLimit },
      },

      relatedProjects: {
        query: relatedProjectsQuery,
        variables: { slug },
      },
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(queryTypes[type]),
    };

    fetch(apiUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        resolve({ result: data.data, error: null });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const pageIds = {
  about: process.env.REACT_APP_CONTENTFUL_ABOUTPAGE_ID,
  extras: process.env.REACT_APP_CONTENTFUL_EXTRAS_ID,
  projects: process.env.REACT_APP_CONTENTFUL_PROJECTS_ID,
  homepage: process.env.REACT_APP_CONTENTFUL_HOMEPAGE_ID,
};
