import styled from "@emotion/styled";
import {
  Breakpoints,
  Colors,
  Transitions,
} from "../../assets/variables/variables";

export const SingleProductDetailsTitle = styled.h2`
  width: 100%;
  position: relative;
  font-size: 25px;
  padding: 20px 0;
  margin-bottom: 40px;
  border-bottom: 2px solid #bdbdbd47;
`;

export const ContentContainer = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  padding: 30px 25px;
  position: relative;
  border: 2px solid ${Colors.$primaryLightColor};
  border-radius: 0 0 12px 12px;
  border-top: 0;

  p {
    padding-bottom: 15px;
  }

  ul,
  ol {
    margin-left: 25px;
    margin-bottom: 15px;
  }

  a {
    color: ${Colors.$primaryColor};
    text-decoration: underline;
  }

  blockquote {
    margin: 0;
    margin-left: 35px;
  }

  @media only screen and (max-width: ${Breakpoints.$small}) {
    padding: 20px 15px 10px 15px;
  }
`;

export const TabsContainer = styled.div`
  background-color: ${Colors.$primaryLightColor};
  border-radius: 6px 6px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const DetailTab = styled.div`
  display: inline-block;
  color: #000;
  border: 1px solid #fff;
  border-radius: 50px;
  cursor: pointer;
  font-size: 17px;
  padding: 10px 40px 10px 40px;
  margin: 10px 20px 10px 20px;
  transition: 0.2s ${Transitions.$superSmooth};

  &.active {
    background-color: #fff;
    color: ${Colors.$primaryColor};

    &:before {
      width: 100%;
    }
  }

  &:hover {
    background-color: #fff;
  }
`;
