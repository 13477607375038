import { useRef, useState } from "react";
import { Flex, Container } from "../../layouts";
import { BigTag, SubTitle, SubmitButton } from "../../layouts/layouts";
import { InfoItem, InputText, ContactForm, InputMessage } from "./layouts";
import { useExtrasData } from "../../Contexts/ExtrasContext";
import Icon from "../../assets/icons/icon";
import emailjs from "@emailjs/browser";

const ContactUs = () => {
  const { extrasData } = useExtrasData();
  const [buttonStatus, setButtonStatus] = useState(false);
  const formRef = useRef();
  const [alertInfo, setAlertInfo] = useState({
    message: "",
    type: "",
  });

  const toggleAlert = (message, type) => {
    if (type === "fail") {
      setAlertInfo({ message, type });

      setTimeout(() => {
        setButtonStatus(false);

        setAlertInfo({
          message: "",
          type: "",
        });
      }, 3500);
    } else {
      setButtonStatus("disabled");
      setAlertInfo({ message, type });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setButtonStatus("loading");

    const name = e.target.name.value;
    const email = e.target.email.value;
    const message = e.target.message.value;
    const subject = e.target.subject.value;

    const templateParams = {
      to_name: "AS Welder",
      from_name: name,
      from_email: email,
      from_subject: subject,
      message: message,
    };

    emailjs
      .send(
        process.env.REACT_APP_EMAIL_SERVICE_ID,
        process.env.REACT_APP_EMAIL_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAIL_PUBLIC_KEY
      )
      .then((response) => {
        if (response && response.status === 204) {
          toggleAlert("Received a 204 response, handle accordingly", "fail");
          return;
        }

        toggleAlert("Mesazhi juaj është dërguar!", "success");
        formRef.current.reset();
      })
      .catch((error) => {
        toggleAlert(
          "Ka ndodhur një gabim, ju lutem provoni përsëri! Error:" +
            error.status,
          "fail"
        );
      })
      .finally(() => {
        setButtonStatus("idle");
      });
  };

  if (!extrasData) return;

  const {
    buyNumber = null,
    address = null,
    buyEmail = null,
    facebook = null,
    instagram = null,
  } = extrasData;

  return (
    <Container>
      <Flex className="flex-column align-center">
        <Flex className="mb-70 text-center flex-column justify-center align-center">
          <h2 className="mb-10">Na Kontaktoni</h2>
          <BigTag font="Light">
            Me një përgjigjie shumë të shpejt, ne mund të ju ndihmojmë në
            kërkesat e juaja!
          </BigTag>
        </Flex>
        <Flex className="mb-50 w-full">
          <Flex className="w-half align-center justify-center flex-column">
            {address && (
              <InfoItem>
                <Icon icon="location" />
                <div className="d-flex flex-column">
                  <SubTitle font="Light">Adresa:</SubTitle>
                  <BigTag>{extrasData.address}</BigTag>
                </div>
              </InfoItem>
            )}
            {buyNumber && (
              <InfoItem>
                <Icon icon="tel" />
                <div className="d-flex flex-column">
                  <SubTitle font="Light">Numri Kontaktues:</SubTitle>
                  <BigTag>{buyNumber}</BigTag>
                </div>
              </InfoItem>
            )}
            {buyEmail && (
              <InfoItem>
                <Icon icon="gmail" />
                <div className="d-flex flex-column">
                  <SubTitle font="Light">E-mail Adresa:</SubTitle>
                  <a
                    href={`mailTo:${buyEmail}`}
                    target="_blank"
                    rel="noreferrer"
                    className="mr-10"
                  >
                    <BigTag>aswelders@gmail.com</BigTag>
                  </a>
                </div>
              </InfoItem>
            )}
            {(facebook || instagram) && (
              <InfoItem>
                <Icon icon="socialNetwork" />
                <div className="d-flex flex-column">
                  <>
                    <SubTitle font="Light">Social Networks:</SubTitle>
                    <Flex className="mt-15">
                      {facebook && (
                        <a
                          href="https://www.facebook.com/as.welder/"
                          target="_blank"
                          rel="noreferrer"
                          className="mr-10"
                        >
                          <Icon icon="facebookColor" />
                        </a>
                      )}
                      {instagram && (
                        <a
                          href="https://www.instagram.com/as.welder/"
                          target="_blank"
                          rel="noreferrer"
                          className="mr-10"
                        >
                          <Icon icon="instagramColor" />
                        </a>
                      )}
                    </Flex>
                  </>
                </div>
              </InfoItem>
            )}
          </Flex>
          <Flex>
            <ContactForm ref={formRef} onSubmit={handleSubmit}>
              <InputText
                placeholder="Emri dhe Mbiemri"
                type="text"
                id="name"
                name="name"
                required={true}
              ></InputText>
              <InputText
                placeholder="E-maili"
                type="text"
                id="email"
                name="email"
                required={true}
              ></InputText>
              <InputText
                placeholder="Subjekti"
                type="text"
                id="subject"
                name="subject"
              ></InputText>
              <InputMessage
                placeholder="Mesazhi"
                type="text"
                id="message"
                name="message"
              ></InputMessage>
              <div className="w-full d-flex align-center">
                {buttonStatus === "loading" ? (
                  "Duke Dërguar..."
                ) : buttonStatus === "disabled" ? (
                  ""
                ) : (
                  <SubmitButton type="submit">Dërgo mesazhin</SubmitButton>
                )}
                {alertInfo.message && (
                  <p className={`ml-20 ${alertInfo.type}`}>
                    {alertInfo.message}
                  </p>
                )}
              </div>
            </ContactForm>
          </Flex>
        </Flex>
      </Flex>
    </Container>
  );
};

export default ContactUs;
