import styled from "@emotion/styled";
import { Colors, Transitions } from "../../assets/variables/variables";

export const SearchContainer = styled.form`
  display: flex;
  margin-left: 10px;
`;

export const SearchBox = styled.button`
  margin-left: 10px;
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 6px;
  background-color: ${Colors.$primaryColor};
  transition: 0.4s ${Transitions.$superSmooth};
  outline: none;
  border: none;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 0px 7px 3px rgb(255 169 21 / 46%);
  }

  svg {
    fill: #fff;
    width: 17px;
    height: 16px;
  }
`;

export const SearchInput = styled.input`
  width: 150px;
  padding: 3px 10px;
  border-radius: 6px;
  border: 1px solid #dddfe4;
  outline: none;
  background-color: #fff;
  color: #000;
  font-weight: 100;
  font-size: 12px;
`;

export const SearchTitle = styled.div`
  display: flex;
  padding: 50px 0;
  align-items: baseline;

  h3 {
    font-weight: 500;
  }

  p {
    padding-left: 5px;
    font-size: 32px;
    font-weight: 900;
  }
`;
