import React from "react";
import Hero from "../hero";
import HeroSimple from "../hero-simple";
import HotOffers from "../hot-offers";
import OurWork from "../our-work";
import OurServices from "../our-services";
import Subscribe from "../hero-simple";
import WorkCategories from "../hero-simple";
import SingleProductHero from "../single-product-hero";
import SingleProductDetails from "../single-product-details";
import RelatedProducts from "../related-products";
import OurApproaches from "../our-approaches";
import OurProducts from "../our-products";
import AboutDetails from "../about-details";
import CategoryBlock from "../category-block";
import OurProjects from "../our-projects";
import { Container } from "../../layouts";


const listOfComponents = {
  post: Hero,
  ourWork: OurWork,
  hotOffers: HotOffers,
  subscribe: Subscribe,
  projects: OurProjects,
  heroSimple: HeroSimple,
  ourServices: OurServices,
  ourProducts: OurProducts,
  aboutDetails: AboutDetails,
  ourApproach: OurApproaches,
  workCategories: WorkCategories,
  relatedProducts: RelatedProducts,
  mixedCategories: CategoryBlock,
  singleProductHero: SingleProductHero,
  singleProductDetails: SingleProductDetails,
};

const DynamicComponent = ({ listOfComponentsType }) => {
  const renderContent = (container, type, data, index) => {
    const Component = listOfComponents[type];

    if (Component) {
      const componentElement = <Component key={index} {...data} />;

      if (container) {
        return <Container>{componentElement}</Container>;
      }

      return componentElement;
    }

    return;
  };

  return (
    <>
      {listOfComponentsType.map((componentData, index) => {
        const componentType = componentData.fields.type;
        const componentContainer = componentData.fields.container;

        return (
          <React.Fragment key={index}>
            {renderContent(
              componentContainer,
              componentType,
              componentData,
              index
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default DynamicComponent;
