import React, { useState } from "react";
import Image from "../image";
import { Flex, DivHalf, Container } from "../../layouts";
import { Quote, Paragraph, Title, TitleExpresion } from "../../layouts/layouts";
import { AboutusImageSplit } from "./layouts";

const AboutDetails = (data) => {
  const [aboutData, setAboutData] = useState(null);

  useState(() => {
    if (data) {
      setAboutData(data.fields);
    }
  }, []);

  if (!aboutData) return;

  const {
    quote = "",
    title = "",
    image = "",
    subtitle = "",
    description = "",
  } = aboutData;

  return (
    <Container>
      <Flex className="pb-70 ">
        <DivHalf>
          <Image
            source={image.fields.file.url}
            classList={"h-full w-full aspect-9-11 object-cover"}
            alt={image.fields.file.fileName}
          />
          <AboutusImageEffect />
        </DivHalf>
        <Flex className="w-half pl-50 justify-center flex-column">
          <TitleExpresion className="mb-30">{title}</TitleExpresion>
          <Title className="pb-30">{subtitle}</Title>
          <Quote className="mb-30">{quote}</Quote>
          <Paragraph className="pb-20">{description}</Paragraph>
        </Flex>
      </Flex>
    </Container>
  );
};

const AboutusImageEffect = () => {
  return (
    <AboutusImageSplit>
      <span />
      <span />
      <span />
    </AboutusImageSplit>
  );
};

export default AboutDetails;
