import React, { useState } from "react";
import "./style.scss";

import { NavLink, useNavigate } from "react-router-dom";
import { SearchBox, SearchContainer, SearchInput } from "./layouts";
import Icon from "../../assets/icons/icon";

const Navigation = () => {
  const [inputValue, setInputValue] = useState("");
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (inputValue) {
      navigate(`/search?q=${inputValue}`);
    }
  };

  return (
    <div className="d-flex align-center">
      <NavLink
        to="/"
        className={"navlink text-decoration-none color-black py-5 px-10"}
      >
        Home
      </NavLink>
      <NavLink
        to="/products"
        className={"navlink text-decoration-none color-black py-5 px-10"}
      >
        Products
      </NavLink>
      <NavLink
        to="/projects"
        className={"navlink text-decoration-none color-black py-5 px-10"}
      >
        Projects
      </NavLink>
      <NavLink
        to="/about"
        className={"navlink text-decoration-none color-black py-5 px-10"}
      >
        About
      </NavLink>{" "}
      <NavLink
        to="/wish-list"
        className={"navlink text-decoration-none color-black py-5 px-10"}
      >
        Wish List
      </NavLink>
      {/* <NavLink
        to="/cart"
        className={"navlink text-decoration-none color-black py-5 px-10"}
      >
        Shopping Cart
      </NavLink> */}
      <NavLink
        to="/contact-us"
        className={"navlink text-decoration-none color-black py-5 px-10"}
      >
        Contact Us
      </NavLink>
      <SearchContainer onSubmit={handleSubmit}>
        <SearchInput
          type="search"
          placeholder="Kërko..."
          value={inputValue}
          onChange={handleInputChange}
        />
        <SearchBox type="submit">
          <Icon icon="search" />
        </SearchBox>
      </SearchContainer>
    </div>
  );
};

export default Navigation;
