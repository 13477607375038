import styled from "@emotion/styled";
import {
  Breakpoints,
  Colors,
  Fonts,
  Transitions,
} from "../../assets/variables/variables.js";

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  max-width: 25%;
  flex-wrap: wrap;
  margin-bottom: 15px;
  margin-right: 15px;
`;

export const FilterForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 15px;
  width: 100%;
  // background-color: #f3f3f3;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;

export const FilterText = styled.span`
  width: 100%;
  font-weight: bold;
  padding: 5px;
  bottom: 3px;
  border-bottom: 1px solid #454545;
`;

export const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px 0;
`;

export const FilterContainerContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .price-pointers {
    display: block;
    width: 20px;
    height: 20px;
    cursor: pointer;
    border-radius: 100%;
    background-color: #fff;
    border: 2px solid ${Colors.$primaryLightColor};
    transition: ${Transitions.$linearSmooth};

    &:active {
      top: -1px;
      transform: translateX(-1px);
      width: 22px;
      height: 22px;
    }
  }

  .price-cloud {
    top: 50%;
    transform: translateY(-50%);
    outline: none;
  }

  .price-cloud-number {
    position: absolute;
    top: -30px;
    left: 50%;
    color: #fff;
    padding: 0 7px;
    font-size: 12px;
    cursor: pointer;
    border-radius: 50px 50px;
    background-color: #272a2f;
    transform: translateX(-50%);

    &:before {
      content: "";
      position: absolute;
      top: 18px;
      left: 50%;
      transform: translateX(-50%);
      border-top: 5px solid #272a2f;
      border-right: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 5px solid transparent;
    }
  }

  .price-slider {
    height: 40px;
  }

  .price-track {
    height: 3px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #ddd;
  }

  .price-track-1 {
    background-color: ${Colors.$primaryColor};
  }
`;

export const PriceTrack = styled.div`
  top: 0;
  bottom: 0;
  border-radius: 999px;
`;

export const ColorCircleContainer = styled.div`
  width: 80%;
  margin-left: 15px;
  margin-bottom: 5px;
  margin-top: 5px;
`;

export const ColorCircle = styled.label`
  width: 100%;
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 0px solid #ccc;
  cursor: pointer;
  margin-top: 7px;
  margin-bottom: 4px;
  margin-right: 16px;

  &.blue {
    background-color: #62cdff;
  }

  &.orange {
    background-color: #fd841f;
  }

  &.cyan {
    background-color: #64c9cf;
  }
  &.orange-light {
    background-color: #ffb740;
  }
  &.green-light {
    background-color: #ade498;
  }
  &.purple-light {
    background-color: #b2a4ff;
  }
  &.red-light {
    background-color: #fe8e9c;
  }
  &.red-purple-light {
    background-color: #fcbcd9;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
  margin-left: 15px;
  margin-bottom: 5px;
  margin-top: 5px;
`;

export const CheckboxLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
  width: 75%;
`;

export const CheckboxInput = styled.input`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

export const PriceInputContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  margin-bottom: 5px;
  margin-top: 5px;

  label {
    width: 100%;
    padding-bottom: 5px;
  }
`;

export const PriceInputField = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;

  font-size: 14px;
  outline: none;
  width: 100%;
`;

export const DummyDataContainer = styled.div`
  width: 300px;
  border: 0px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 25px;
  margin-right: 15px;
`;
export const DummyDataSubContainer = styled.div`
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const DummyDataCategory = styled.p`
  font-size: 14px;
  color: #888;
  margin-bottom: 8px;
`;
export const DummyDataTitle = styled.p`
  font-size: 17px;
  margin-bottom: 8px;
`;
export const DummyDataPrice = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
`;

export const DummyDataImageWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 166.9%;
  overflow: hidden;
`;

export const FilterSubmitBtn = styled.button`
  font-size: 12px;
  cursor: pointer;
  letter-spacing: 1px;
  padding: 12px 24px;
  border: 1px solid rgb(120, 120, 120);
  background: transparent;
  transition: 0.3s ${Transitions.smooth};
  font-weight: 600;

  &:hover {
    color: #fff;
    background-color: ${Colors.$primaryColor};
    border: 1px solid ${Colors.$primaryColor};
  }
`;

export const Select = styled.select`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
  width: 100%;
`;

export const FilterTotal = styled.p`
  position: relative;
  line-height: 1;
  font-size: 18px;
  padding-left: 13px;
  font-family: ${Fonts.$regular};

  &:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 7px;
    left: 0;
    border-radius: 3px;
    background-color: ${Colors.$primaryColor};
  }
`;

export const Seperator = styled.span`
  width: 100%;
  height: 1px;
  background-color: #d3d2d2;
  // background-color: #f6f6f6;
`;

export const FilterTitle = styled.p`
  font-size: 15px;
  color: #b2b7bc;
  position: relative;
  font-weight: 100;
  padding-bottom: 5px;
  font-family: ${Fonts.$medium};
`;

export const MobileDropdown = styled.div`
  display: none;

  @media (max-width: ${Breakpoints.$small}) {
    display: block;
  }
  .Dropdown-control,
  .Dropdown-menu {
    border: 1px solid ${Colors.$primaryMediumColor};
  }

  .Dropdown-menu {
    border-top: none;
  }
`;

export const ActiveSortButton = styled.div`
  display: none;
  align-items: center;
  padding: 2px 8px;
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid ${Colors.$primaryMediumColor};
  transition: 0.2s ${Transitions.$linearSmooth};
  user-select: none;

  svg {
    width: 29px;
    height: 23px;

    path {
      stroke: ${Colors.$primaryMediumColor};
    }
  }

  p {
    user-select: none;
    letter-spacing: 0.5px;
    color: ${Colors.$primaryMediumColor};
  }

  &:active {
    transform: scale(0.95);
  }

  @media only screen and (max-width: ${Breakpoints.$small}) {
    display: flex;
  }
`;
