import { cubicBezier } from "framer-motion";

export const titleAnime = {
  initial: {
    x: "100%",
    opacity: 0,
  },
  animate: {
    x: "0",
    opacity: 1,
  },
  viewport: {
    once: true,
    amount: 0.00001,
  },
  transition: {
    duration: 0.8,
    ease: cubicBezier(0.25, 1, 0.5, 1),
    delay: 0.4,
  },
};

export const btnAnime = {
  initial: {
    x: "100%",
    opacity: 0,
  },
  animate: {
    x: "0",
    opacity: 1,
  },
  viewport: {
    once: true,
    amount: 0.00001,
  },
  transition: {
    duration: 1,
    ease: cubicBezier(0.25, 1, 0.7, 1),
    delay: 0.6,
  },
};
