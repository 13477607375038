import styled from "@emotion/styled";
export const AboutusImageSplit = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  span {
    height: 10%;
    position: absolute;
    background: white;

    &:nth-of-type(1) {
      width: 40%;
    }

    &:nth-of-type(2) {
      left: 35%;
      width: 5%;
      height: 100%;
    }

    &:nth-of-type(3) {
      bottom: 0;
      right: 0;
      width: 60%;
    }
  }
`;

export const BoxContainer = styled.div`
  width: 100%;
  justify-content: center;
  padding-top: 25px;
  padding-bottom: 25px;
  background: -webkit-linear-gradient(0deg, #ee390f 0%, #f9b700 100%);
`;

export const InfoBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 50px;
  margin-left: 50px;
`;

export const InfoBoxDataNumber = styled.div`
  font-size: 50px;
  font-weight: bold;
  color: #fff;
`;

export const InfoBoxArrow = styled.span`
  width: 50%;
  height: 1px;
  background-color: #fff;
  margin-right: auto;
  margin-left: auto;
  border-width: 4px;
  border-color: #fff;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const InfoBoxDataText = styled.div`
  font-size: 20px;
  color: #fff;
`;
