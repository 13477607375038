import { useEffect, useState } from "react";
import SingleProductHero from "../components/single-product-hero";
import SingleProductDetails from "../components/single-product-details";

import RelatedProducts from "../components/related-products";
import { Container } from "../layouts";
import { useParams } from "react-router-dom";
import { getQueryData } from "../config/data";

const SingleProduct = () => {
  const [product, setProduct] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState(null);
  const params = useParams();
  const [pageActive, setPageActive] = useState(false);

  useEffect(() => {
    getQueryData({
      type: "products",
      slug: params.slug,
      imagesLimit: 9,
      itemsLimit: 1,
    })
      .then((data) => {
        const product = data.result.productCollection.items[0];
        const categories = product.productCategoriesCollection.items;

        let categorySlug =
          categories.find((category) => category.mixedCategory) || null;
        if (!categorySlug) {
          categorySlug = categories[0];
        }
        setProduct(product);
        getRelatedProducts(categorySlug.slug);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    function getRelatedProducts(cat) {
      getQueryData({
        type: "categoryProducts",
        categorySlug: cat,
        itemsLimit: 4,
        currentProductSlug: params.slug,
      })
        .then((data) => {
          setRelatedProducts(data.result.productCollection.items);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [params]);

  useEffect(() => {
    setTimeout(() => {
      if (product) {
        setPageActive(true);
      }
    }, 150);
  }, [product]);

  if (!product) return "Loading...";

  return (
    <Container>
      <SingleProductHero data={product} />
      <SingleProductDetails data={product} />
      {relatedProducts && <RelatedProducts data={relatedProducts} />}
    </Container>
  );
};

export default SingleProduct;
