import { Container, Flex } from "../../layouts";
import { BigTitle, Paragraph, SubTitle } from "../../layouts/layouts";
import Image from "../image";
import { HeroContainer, HeroContent, HeroImage } from "./layouts";

const ProjectHero = ({ data }) => {
  const {
    title = "",
    subtitle = "",
    description = "",
    imagesCollection = null,
  } = data;

  return (
    <HeroContainer>
      <HeroImage>
        {imagesCollection && <Image source={imagesCollection.items[0].url} />}
      </HeroImage>
      <Container className="h-full">
        <HeroContent>
          <BigTitle>{title}</BigTitle>
          <Flex>
            <h2>{subtitle}</h2>
            <Paragraph>{description}</Paragraph>
          </Flex>
        </HeroContent>
      </Container>
    </HeroContainer>
  );
};

export default ProjectHero;
