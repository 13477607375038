import { Container } from "../../layouts";
import Icon from "../../assets/icons/icon";
import { ContentContainer, Description, Galery, Seperator } from "./layouts";
import RenderText from "../render-text";
import GalerySlider from "./galery-slider";
import RelatedProjects from "./related-projects";

const ProjectContent = ({ data }) => {
  const { specifications = null, imagesCollection = null } = data;

  return (
    <ContentContainer>
      <Container>
        {specifications && (
          <>
            <Seperator>
              <p>Përmbajtja e projektit</p>
              <span>01</span>
            </Seperator>
            <Description>
              {RenderText(specifications)}
              <Icon icon="content" />
            </Description>
          </>
        )}
        {imagesCollection && (
          <>
            <Seperator>
              <p>Galeria e projektit</p>
              <span>02</span>
            </Seperator>
            <Galery>
              <GalerySlider data={imagesCollection} />
            </Galery>
          </>
        )}
        <Seperator>
          <p>Projektet e ngjashme</p>
          <span>03</span>
        </Seperator>
        <RelatedProjects />
      </Container>
    </ContentContainer>
  );
};

export default ProjectContent;
