import styled from "@emotion/styled";
import { motion } from "framer-motion";
import { Laptop, Mobile } from "../../assets/mixins/_mixins";

export const CategoryTitleEffect = styled(motion.div)`
  position: absolute;
  width: 220px;
  top: 10%;
  right: 10px;
  border-radius: 3px;
  aspect-ratio: 9/11;
  box-shadow: 0px 0px 20px 2px rgb(0 0 0 / 50%);
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background: #fff;
    opacity: 0.37;
  }

  h2 {
    font-weight: 400;
    z-index: 2;
  }
`;

export const CategoryTitleEffectReverse = styled.div`
  & > div {
    right: initial;
    left: 10px;
  }
`;
