import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./assets/scss/style.scss";
import Home from "./pages/home";
import About from "./pages/about";
import ErrorPage from "./pages/error";
import Search from "./pages/search";
import Header from "./components/header";
import Footer from "./components/footer";
import SingleProduct from "./pages/singleproduct";
import Products from "./pages/products";
import Category from "./pages/category";
import SubscribeModal from "./components/subscribe-modal";
import Commingsoonpage from "./pages/commingsoonpage";
import Contact from "./pages/contactus";
import WishList from "./pages/wishList";
import Projects from "./pages/projects";
import SingleProject from "./pages/singleproject";
import { ExtrasDataProvider } from "./Contexts/ExtrasContext";
import { AnimatePresence } from "framer-motion";
import UnderConstruction from "./components/under-construction";

function RoutesWithAnimation() {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [userFirstLogin, setUserFirstLogin] = useState(
    localStorage.getItem("userFirstLogin")
  );
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!userFirstLogin) {
      const timeoutId = setTimeout(() => {
        document.body.style.overflowY = "hidden";
        setShowModal(true);
        setUserFirstLogin(true);
      }, 5000);

      return () => clearTimeout(timeoutId);
    }
  }, [userFirstLogin]);

  const closeModal = () => {
    localStorage.setItem("userFirstLogin", false);
    setShowModal(false);
    document.body.style.overflowY = "initial";
  };

  // const removeLoadingScreen = () => {
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 2900);
  // };

  return (
    <AnimatePresence mode="wait">
      <UnderConstruction />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/*" element={<ErrorPage />} />
        <Route path="/search" element={<Search />} />
        <Route path="/products" element={<Products />} />
        <Route path="/wish-list" element={<WishList />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/category/:slug" element={<Category />} />
        <Route path="/projects/:slug" element={<SingleProject />} />
        <Route path="/products/:slug" element={<SingleProduct />} />
        {/* <Route path="/cart" element={<ShoppingCart />} /> */}
      </Routes>
      <Footer />
      {showModal && <SubscribeModal removeModal={closeModal} />}
    </AnimatePresence>
  );
}

const App = () => {
  return (
    <BrowserRouter>
      <ExtrasDataProvider>
        <div className={`p-relative error-page`}>
          <Header />
          <RoutesWithAnimation />
        </div>
      </ExtrasDataProvider>
    </BrowserRouter>
  );
};

export default App;
