import { useEffect, useState } from "react";
import { Flex } from "../../layouts";
import { BigTitle, Title, Paragraph } from "../../layouts/layouts";
import { motion } from "framer-motion";
import Button from "../button";
import Image from "../image";
import {
  textAnimation,
  titleAnimation,
  imageAnimation,
  squareAnimation,
  buttonAnimation,
  squareAnimationReverse,
} from "./animevars";
import {
  HeroImage,
  HeroContent,
  SquareAnime,
  SquareAnimeBig,
  HeroImageReverse,
  HeroContentReverse,
} from "./layouts";

const HeroStraight = (data) => {
  if (!data) return;

  const { title = "", description = "", image = "" } = data;

  return (
    <Flex className="mb-100">
      <HeroImage>
        <motion.div
          className="h-full w-full"
          variants={imageAnimation}
          initial="initial"
          whileInView="animate"
          transition={imageAnimation.transition}
          viewport={{
            once: true,
            margin: "0px 0px 180px 0px",
          }}
        >
          <Image source={image.fields.file.url} alt={title} />
        </motion.div>
        <motion.div
          variants={squareAnimation}
          initial={"initial"}
          whileInView={"initialAnimate"}
          transition={squareAnimation.parentTransition}
          className="h-full w-full index-negative-1 p-absolute"
        >
          <SquareAnime
            variants={squareAnimation}
            animate={"anime"}
            transition={squareAnimation.transition}
          />
        </motion.div>
      </HeroImage>
      <HeroContent className="align-right">
        <div className="overflow-hidden mb-50">
          <BigTitle
            variants={titleAnimation}
            initial="initial"
            whileInView="animate"
            viewport={titleAnimation.viewport}
            transition={titleAnimation.transition}
          >
            {title}
          </BigTitle>
        </div>
        <div className="overflow-hidden mb-30 w-75">
          <Paragraph
            font="Light"
            variants={textAnimation}
            initial="initial"
            whileInView="animate"
            viewport={textAnimation.viewport}
            transition={textAnimation.transition}
          >
            {description}
          </Paragraph>
        </div>
        <div className="d-inline-block overflow-hidden w-fit pr-1">
          <Button
            text="Shiko të gjitha"
            to={`/projects`}
            anime={buttonAnimation}
          />
        </div>
      </HeroContent>
    </Flex>
  );
};

const HeroReverse = (data) => {
  if (!data) return;

  const { title = "", description = "", image = "" } = data;

  return (
    <Flex className="mb-100">
      <HeroContentReverse>
        <div className="d-inline-block overflow-hidden w-fit pr-1 mb-30">
          <Button
            text="Shiko të gjitha"
            to={`/projects`}
            anime={buttonAnimation}
          />
        </div>
        <div className="overflow-hidden mb-30">
          <Title
            variants={titleAnimation}
            initial="initial"
            whileInView="animate"
            viewport={titleAnimation.viewport}
            transition={titleAnimation.transition}
          >
            {title}
          </Title>
        </div>
        <div className="overflow-hidden mb-30 w-75">
          <Paragraph
            font="Light"
            variants={textAnimation}
            initial="initial"
            whileInView="animate"
            viewport={textAnimation.viewport}
            transition={textAnimation.transition}
          >
            {description}
          </Paragraph>
        </div>
      </HeroContentReverse>
      <HeroImageReverse>
        <motion.div
          variants={imageAnimation}
          initial="initial"
          whileInView="animate"
          transition={imageAnimation.transition}
          viewport={{
            once: true,
            margin: "0px 0px -200px 0px",
          }}
        >
          <Image source={image.fields.file.url} alt={title} />
        </motion.div>
        <motion.div
          variants={squareAnimation}
          initial={"initial"}
          whileInView={"initialAnimate"}
          transition={squareAnimation.parentTransition}
          className="h-full w-full index-negative-1 p-absolute"
          viewport={{ once: true, margin: "0px 0px -50% 0px" }}
        >
          <SquareAnimeBig
            variants={squareAnimationReverse}
            whileInView={"anime"}
            transition={squareAnimationReverse.transition}
            viewport={{
              once: true,
              margin: "0px 0px -150px 0px",
            }}
          />
        </motion.div>
      </HeroImageReverse>
    </Flex>
  );
};

const Hero = (data) => {
  const [heroData, setHeroData] = useState();

  useEffect(() => {
    if (data) {
      setHeroData(data.fields);
    }
  }, []);

  if (!heroData) return;
  const { reverse = false } = heroData;

  return (
    <Flex className={`mb-70 ${reverse && "align-end"}`}>
      {reverse ? <HeroReverse {...heroData} /> : <HeroStraight {...heroData} />}
    </Flex>
  );
};

export default Hero;
