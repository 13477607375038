import React from "react";
import Image from "../image";
import { Container, Flex } from "../../layouts";
import { BigTitle, Paragraph } from "../../layouts/layouts";

const HeroSimple = ({ data }) => {
  const { title = "", image = null } = data;

  return (
    <Flex className="mb-70 p-relative">
      <Image
        source={image.url}
        classList={"w-full h-full object-cover z-negative-1 p-absolute"}
        alt={image?.fields?.file?.fileName}
      />
      <Container>
        <div className="py-70">
          <BigTitle className="pb-20 p-relative index-positive-1">
            {title}
          </BigTitle>
          {/* <Paragraph>Some details what we do / Projects</Paragraph> */}
        </div>
      </Container>
    </Flex>
  );
};

export default HeroSimple;
