import React from "react";
import { Container } from "../layouts";
import ContactUs from "../components/contact-us";

const Contact = () => {
  return (
    <Container>
      <ContactUs />
    </Container>
  );
};

export default Contact;
