import React from "react";
import { Container } from "../layouts";
import { BigTitle } from "../layouts/layouts";
import Icon from "../assets/icons/icon";
import styled from "@emotion/styled";

const ErrorContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Error = () => {
  return (
    <>
      <div className="p-absolute-fixed index-negative-1">
        <Icon icon="notFoundPage" />
      </div>
      <ErrorContainer>
        <Container>
          <BigTitle className="error-title">404: Page do not exists</BigTitle>
        </Container>
      </ErrorContainer>
    </>
  );
};

export default Error;
