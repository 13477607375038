import styled from "@emotion/styled";
import { Colors, Fonts } from "../../assets/variables/variables.js";
import { motion } from "framer-motion";

export const Service = styled(motion.div)`
  width: calc(33% - 10px);
  display: flex;
  background-color: #0f0f0e;
  padding: 25px 10px;
  z-index: 1;

  p {
    color: #717373;
  }

  svg {
    width: 100%;
    height: 100%;
    padding: 10px;
  }
`;

export const ServiceIcon = styled.div`
  position: relative;
  width: 100%;
  heigth: 100%;
  border: 2px solid ${Colors.$primaryColor};
  aspect-ratio: 1/1;

  &:before,
  &:after,
  span:before,
  span:after {
    content: "";
    position: absolute;
    background-color: ${Colors.$primaryColor};
  }

  &:before {
    left: -2px;
    top: -12px;
    height: 10px;
    width: 2px;
  }

  &:after {
    left: -12px;
    top: -2px;
    height: 2px;
    width: 10px;
  }

  span {
    position: relative;

    &:before {
      right: -2px;
      bottom: -13px;
      height: 10px;
      width: 2px;
    }

    &:after {
      right: -12px;
      bottom: -3px;
      height: 2px;
      width: 10px;
    }
  }
`;

export const ServiceTag = styled.span`
  position: relative;
  font-family: ${Fonts.$regular};
  font-size: 25px;
  color: ${Colors.$primaryColor};

  &:before {
    content: "";
    position: absoulte;
    width: 30px;
    height: 2px;
    top: 50%;
    left -40px;
    background-color: ${Colors.$primaryColor};
  }
`;
