import { useEffect, useState } from "react";
import { Container } from "../layouts";
import { getQueryData } from "../config/data";
import OurProjects from "../components/our-projects";
import { useParams } from "react-router";

const Projects = () => {
  const [projects, setProjects] = useState(null);
  const params = useParams();

  useEffect(() => {
    if (!projects) {
      getQueryData({
        type: "projects",
        slug: params.slug,
        imagesLimit: 1,
      })
        .then((data) => {
          setProjects(data.result.projectCollection);
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    }
  }, []);

  if (!projects) return;
  return (
    <Container>
      <OurProjects data={projects} />
    </Container>
  );
};

export default Projects;
