import styled from "@emotion/styled";
import { Colors, Fonts } from "../../assets/variables/variables";

export const ContentContainer = styled.div`
  padding: 50px 0;
  width: 100%;
  position: relative;
  background-color: ${Colors.$colorIcingRose};

  &:before {
    width: 100%;
    height: 200px;
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    background: linear-gradient(
      0deg,
      rgba(245, 238, 231, 1) 0%,
      rgba(245, 238, 231, 0) 100%
    );
  }
`;

export const Description = styled.div`
  position: relative;
  padding: 30px 0;
  margin-bottom: 70px;

  p {
    position: relative;
    width: 60%;
    padding-bottom: 50px;
    z-index: 1;
  }

  svg {
    height: 100%;
    width: 50%;
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0.5;
  }
`;

export const Seperator = styled.div`
  padding-top: 10px;
  margin-bottom: 50px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #000;
  padding-bottom: 10px;

  p,
  span {
    font-size: 14px;
    font-family: ${Fonts.$extraLight};
  }

  span {
    display: inline-block;
    position: relative;
  }
`;

export const Galery = styled.div`
  position: relative;
`;
