import styled from "@emotion/styled";
import { Colors } from "../../assets/variables/variables.js";
import { motion } from "framer-motion";

export const HeroImage = styled.div`
  position: relative;
  width: 50%;
  aspect-ratio: 1/1;
  display: flex;
  border-radius: 12px;
  padding-right: 30px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    object-fit: cover;
  }
`;

export const SquareAnime = styled(motion.div)`
  position: absolute;
  width: 45%;
  height: 45%;
  bottom: -37px;
  left: -37px;
  border-radius: 12px;
  background-color: ${Colors.$primaryColor};
  z-index: -1;
`;

export const HeroImageReverse = styled.div`
  position: relative;
  width: 60%;
  aspect-ratio: 1.45/1;
  display: flex;
  border-radius: 12px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    object-fit: cover;
  }
`;

export const SquareAnimeBig = styled(motion.div)`
  position: absolute;
  width: 80%;
  height: 75%;
  bottom: -30px;
  right: -37px;
  border-radius: 12px;
  background-color: ${Colors.$primaryColor};
  z-index: -1;
`;

export const HeroContent = styled.div`
  width: 50%;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
`;

export const HeroContentReverse = styled.div`
  width: 40%;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
`;
