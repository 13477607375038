import styled from "@emotion/styled";
import { Fonts, Transitions } from "../../../assets/variables/variables.js";
import { css } from "@emotion/react";
import { Link } from "react-router-dom";

export const ProjectContainer = styled(Link)`
  height: 500px;
  width: 100%;
  position: relative;
  display: flex;
  cursor: pointer;

  ${(props) =>
    props.reverse
      ? css`
          flex-direction: row-reverse;

          &:hover {
            .image-animation {
              transition: 0.6s ${Transitions.$superSmooth};
              transform: translateX(-100%);
            }
          }
        `
      : css`
          &:hover {
            .image-animation {
              transition: 0.6s ${Transitions.$superSmooth};
              transform: translateX(100%);
            }
          }
        `};
`;

export const Content = styled.div`
  flex: 0 0 50%;
  height: 100%;
  padding: ${(props) =>
    props.reverse ? "15px 50px 15px 50px" : "15px 50px 15px 15px"};

  p {
    font-size: 16px;
    margin-bottom: 15px;
    font-family: ${Fonts.$light};
  }
`;

export const ProjectInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  flex: 0 0 50%;
  padding: ${(props) =>
    props.reverse
      ? "10px 30px 10px 10px;  align-items: flex-start"
      : "10px 10px 10px 30px;  align-items: flex-end"};

  > div {
    ${(props) => (props.reverse ? "text-align: left;" : "text-align: right;")};
  }
`;

export const Title = styled.h2`
  font-size: 56px;
  line-height: 1.1;
  font-family: ${Fonts.$extraLight};
  margin-bottom: 10px;
`;

export const Subtitle = styled.h4`
  font-size: 32px;
  font-family: ${Fonts.$light};
`;

export const SeeMoreLink = styled.p`
  font-size: 18px;
  font-family: ${Fonts.$light};
  letter-spacing: 1.5px;

  ${(props) => props.decoration && "text-decoration: underline"};
`;

export const ImageContainer = styled.div`
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  transition: 1.5s ${Transitions.$superSmooth};
  transition-delay: 3s;

  ${(props) => (props.reverse ? "right: 0" : "left: 0")};

  img {
    width: 100%;
    height: 100%;
  }
`;
