import React, { useEffect, useState } from "react";
import {
  BoxContainer,
  InfoBoxArrow,
  InfoBoxContainer,
  InfoBoxDataNumber,
  InfoBoxDataText,
} from "../about-details/layouts";
import { Container, Flex } from "../../layouts";

const OurWork = (data) => {
  const [workStats, setWorkStats] = useState(null);

  useEffect(() => {
    if (data) {
      const numericStats = data.fields.numericStats;
      const titleStats = data.fields.titleStats;

      const combinedStats = titleStats.reduce((acc, value, index) => {
        acc[index] = { title: value, numeric: numericStats[index] };
        return acc;
      }, {});

      setWorkStats(combinedStats);
    }
  }, []);

  if (!data) return;

  return (
    <Flex className="mb-70 p-relative">
      <BoxContainer>
        <Container className="d-flex justify-center">
          <Flex>
            {workStats &&
              Object.entries(workStats).map((workStat, index) => {
                const { title = "Stat", numeric = "-" } = workStat[1];

                return (
                  <InfoBoxContainer key={index}>
                    <InfoBoxDataNumber>{numeric}</InfoBoxDataNumber>
                    <InfoBoxArrow></InfoBoxArrow>
                    <InfoBoxDataText>{title}</InfoBoxDataText>
                  </InfoBoxContainer>
                );
              })}
          </Flex>
        </Container>
      </BoxContainer>
    </Flex>
  );
};

export default OurWork;
